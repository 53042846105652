<template>
    <div class="cash">
        <div class="content-center">
            <div class="title">项目案例</div>
             <el-carousel indicator-position="outside">
                <el-carousel-item v-for="items in dataList" :key="items[0].id" >
                    <div class="card-item">
                        <card :item="entity" class="card" v-for="entity in items" :key="entity.id"></card>
                        
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import card from './components/Card.vue'
import { getProjectCases } from "@/api/home/home.js";
export default {
    components:{
        card,
    },
    data(){
        return{
            dataList:[]
        }
    },
    created () {
    this.load();
  },
  methods: {
    load() {
      getProjectCases({ pageSize: 20 }).then(res => {
        this.dataList = this.dealWithArray(res.rows,4);
      });
    },
    dealWithArray(array,chunkSize){
        let result = [];
  
         for (let i = 0; i < array.length; i += chunkSize) {
                let chunk = array.slice(i, i + chunkSize);
                result.push(chunk);
            }
  
  return result;
    }
  }

}
</script>
<style scoped lang="less">
.cash{
 
    .title{
        padding: 40px 0 35px 0;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        color: #2C2C2C;
        line-height: 35px;
    }
 
    .card-item{
        display: flex;
        flex-direction: row;
    }
    .card-item .card:nth-child( -n + 3){
        margin-right: 17px;
    }
.el-carousel{
   ::v-deep .el-carousel__indicator--horizontal {
  padding: 19px 5px;

  .el-carousel__button {
    width: 12px;
    height: 5px;
    border-radius: 2.5px;
    background: rgba(223, 165, 27, 0.3);
  }

  &.is-active .el-carousel__button {
    width: 38px;
    background: rgba(223, 165, 27, 1);
  }
}
}
 
}
 

</style>