/**
 * @description: 根据文件名判断文件类型
 * @param {String} fileName 文件名
 */
 function getFileType(fileName) {
    if (fileName == null || fileName.indexOf(".") == -1) {
        return 'other';
    }
    // 文件后缀分类
    let fileSuffix = {
        video: ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'],
        audio: ['mp3', 'wav', 'wmv'],
        image: ['bmp', 'jpg', 'jpeg', 'png', 'gif'],
        word: ['doc', 'docx'],
        excel: ['xls', 'xlsx'],
        ppt: ['ppt', 'pptx', 'pptm'],
        pdf: ['pdf'],
        txt: ['txt']
    };
    // 判断文件类型
    let suffix = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
    for (let key in fileSuffix) {
        if (fileSuffix[key].includes(suffix)) {
            return key;
        }
    }
    return 'other';
}

export default {
    getFileType
}