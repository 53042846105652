<template>
    <el-dialog  :destroy-on-close='true' width="943px" :visible.sync="dialogVisible" :close-on-click-modal= "false" @close="closeDialog()">
        <div v-if="dialogVisible" class="login-container">
             <div class="log-title">账号登录</div>
             <div class="login-action row-direction">
                <code-login ref="codeRef" @closeLoginDialog="closeLoginDialog()" class="code-login"></code-login>
                <div class="linev"></div>
                <el-tabs class="el-tabs " v-model="activeName"  >
                  <el-tab-pane label="密码登录" name="first">
                    <password-login @closeLoginDialog="closeLoginDialog()" ref="passwordLogin" @popup="popupSlidingCaptcha('passwordLogin')"></password-login>
                  </el-tab-pane>
                  <el-tab-pane label="验证码登录" name="second">
                    <phone-login @closeLoginDialog="closeLoginDialog()" ref="phoneLogin" @popup="popupSlidingCaptcha('phoneLogin')"></phone-login>
                  </el-tab-pane>
                </el-tabs>
             </div>
              <div class="policy">
                <el-link >阅读并接受易隆创 </el-link>
                <el-link @click="goServe()"  type="warning">服务条款</el-link>
                <el-link  >和</el-link>
                <el-link @click="goPolicy()" type="warning">隐私政策</el-link>
              </div>
        </div>
     <!-- 滑块验证 -->
		<div ref="slidingCaptcha"></div>
    </el-dialog>
</template>

<script>
import CodeLogin from './components/codeLogin.vue';
import PasswordLogin from './components/passwordLogin.vue';
import PhoneLogin from './components/PhoneLogin.vue';
import slidingCaptcha from '@/components/sliding-captcha';
import {getCurrentUser} from "@/api/home/home.js";
import store  from "@/store/index.js";
export default  {
  
  name: "login_dialog",
  components:{
    CodeLogin,
    PasswordLogin,
    PhoneLogin
  },
  data() {
    return {
      dialogVisible:false,
      activeName:'first'
    };
  },
  methods:{
    closeLoginDialog(){
      this.dialogVisible = false
       
       getCurrentUser().then((user)=>{
        store.dispatch('setCurrentUser', user.model);
       })
 
    },
 /**
		 * @description 弹出滑块验证
		 */
		popupSlidingCaptcha(ref) {
			slidingCaptcha.init({
				el: this.$refs['slidingCaptcha'],
				type: 'popup', // popup：弹出式，trigger：触发式，embed：内嵌式
				onSuccess: key => {
					this.$refs[ref].getVerificationCode(key);
				},
				onFail: () => {}
			});
		},
    goServe(){
      window.open(this.$router.resolve({path:'/servePolicy/serve'}).href ,'_blank')
    },
    goPolicy(){
       window.open(this.$router.resolve({path:'/servePolicy/policy'}).href ,'_blank')
    },
    closeDialog(){
      this.$refs['codeRef'].stopInterval();
    }

  }
}
</script>

<style scoped lang="less">
@import '~@/components/sliding-captcha/main.css';
.login-container{
   width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .log-title{
    font-size: 32px;
    font-weight: 500;
    color: #323232; 
  }
  .row-direction{
    display: flex;
    width: 823px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
 
  .el-tabs{
    height: 345px;
  /deep/ .el-tabs__nav-scroll{
      display: flex;
      justify-content:space-around;
    
 }
    /deep/ .el-tabs__item{
      color: #2c2c2c;
      padding: 0 75px;
      font-size: 20px;
    } 
    /deep/ .el-tabs__item.is-active{
      color: #DFA51B;
      font-size: 20px;
    } 
    /deep/  .el-tabs__active-bar{
      background-color: #DFA51B;
    }

  } 
}

/deep/.el-dialog{
  padding-left: 0px;
  min-width: 943px;
  height: 616px;
}

.linev{
  margin: 20px 50px 0 59px;
  width: 1px;
  height: 411px;
  background-color:#D8D8D8 ;
  opacity: 0.8;
  
}
.policy{
  padding-left: 330px;
}
</style>