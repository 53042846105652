/**
 * @FileDescription: 工具类/组件 全局注册
 * @Author: zhuangjinshan
 * @Date: 2023-08-16
 * @LastEditors: zhuangjinshan
 * @LastEditTime: 2023-08-16
 */
import Vue from "vue";

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// element-ui message二次封装
import message from './message.js';
Vue.prototype.$message = message;