<template>
  <div class="time-line">
    <div class="content-center">
      <h2 class="time-line-title">隆创新基建引领者</h2>
      <div class="time-line-remark">提供行业定制化IT服务整体解决方案</div>
      <div class="time-line-container" :style="{ '--sum': timeLines.length, '--item-h': '335px' }">
        <div class="timing-item" :style="{ '--i': index }" v-for="(item, index) in timeLines" :key="index">
          <div class="title">{{ item.year }}</div>
          <img class="dotted-line" src="@/assets/img/aboutUs/dotted_line.png">
          <div class="content" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeLines: [
        {
          year: 2023,
          content: "分公司数量突破30+</br>云企业入驻数量超2000+</br>签约项目数3000+。",
        },
        {
          year: 2022,
          content:
            "获得高新技术企业称号：新增音视频集成工程企业能力等级壹级、售后服务认证证书;新增分公司2家</br>推出:智慧消防管理平台;智慧源管理系统;智能网络边界运维平台;智慧神经网络物联大数据可视化建模分析服务平台;智慧旅游管理平台。",
        },
        {
          year: 2021,
          content:
            "新增资质:公路交通工程(公路机电工程分项)专业承包贰级、信息系统建设和服务能力(CS)等级证书;</br>新增分公司 5家推出:智慧钢铁一体化管控平台;智慧火电厂;智慧农业平台;数字果园平台;智慧水务综合管控平台。",
        },
        {
          year: 2020,
          content:
            "进驻中国(南京)软件谷;与海康威视、华为、捷顺、ITC达成战略合作;获得CMMI资质、ITSS资质建筑装修装饰工程专业承包贰级资质；</br>分公司达到30余家推出:智慧医院综合管理平台;智慧风电管理平台;智慧城市可视化管理平台。",
        },
        {
          year: 2019,
          content:
            "获得电子与智能化工程承包壹级资质；</br>分公司达到15家推出:智慧园区统一运营平台;应急预案综合管理平台。",
        },
        {
          year: 2017,
          content:
            "通过了国家级高新技术企业;江苏省优秀企业，并且获得多个专利及软件著作权。推出:智慧畜牧物联网系统。",
        },
        {
          year: 2016,
          content:
            "战略转型，致力于成为行业综合解决方案服务商。</br>推出:智慧公路综合解决方案。",
        },
        {
          year: 2014,
          content:
            "通过ISO健康和环境体系认证获得江苏省安防工程企业壹级资质;银行资信AAA企业劳动与保障诚信企业。",
        },
        {
          year: 2011,
          content:
            "获得建筑智能化工程设计与施工一体化二级资质;计算机系统集成及服务三级资质;ISO9001质量体系认证。",
        },
        {
          year: 2007,
          content: "隆创信息建立",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.time-line {
  padding: 70px 0;
  .time-line-title {
    color: #2c2c2c;
    font-size: 32px;
    text-align: center;
  }
  .time-line-remark {
    margin: 19px 0 80px;
    color: #9e9e9e;
    font-size: 22px;
    text-align: center;
  }
  .time-line-container {
    height: calc(((var(--sum) + 1) * var(--item-h)) / 2);
    background-image: linear-gradient(
      #2c2c2c 0%,
      #2c2c2c 15%,
      transparent 15%,
      transparent 40%,
      #2c2c2c 40%,
      #2c2c2c 75%,
      transparent 75%,
      transparent 100%
    );
    background-position: center;
    background-size: 2px 30px;
    background-repeat: repeat-y;
    position: relative;

    .timing-item {
      width: 575px;
      height: var(--item-h);
      position: absolute;
      top: calc((var(--i) * var(--item-h)) / 2);
      left: 50%;
      transform: translate(-100%, 70px);
      &:nth-child(even) {
        transform: translate(0%, 70px);
        .dotted-line {
          transform: rotateY(180deg) translateX(12px);
        }
      }
      &:last-child {
        height: calc(var(--item-h) - 70px);
      }
      .title {
        padding: 0 40px;
        color: #464545;
        font-size: 45px;
        font-weight: bold;
      }
      .dotted-line {
        transform: translateX(-7px);
      }
      .content {
        padding: 30px 40px;
        color: #9E9E9E;
        font-size: 18px;
        line-height: 1.5;
        text-align: justify;
      }
    }
  }
}
</style>
