<template>
	<div class="password-login-tab" :class="{ 'paddingTop': status === 1 }">
        <div class="password-login-tip success-tip " v-if="tipStatus == 'success'">
            <div class=" ">
                <span class="tip_text">{{tipMessage}}</span>
            </div>
            <div @click="closeTip" class="cursor-pointer"></div>
        </div>
        <div class="password-login-tip error-tip " v-if="tipStatus == 'error'">
            <div class=" ">
                <span class="tip_text">{{tipMessage}}</span>
            </div>
            <div @click="closeTip" class="cursor-pointer"></div>
        </div>
		<el-form ref="account" :model="entity" :rules="rules"  label-position="left" hide-required-asterisk>
			<div class="form-item-container" :class="{'show-code': status === 1}">
				<el-form-item  prop="username">
					<el-input
						ref="usernameInput"
						v-model="entity.username"
						@keyup.native.enter="focusInput('passwordInput')"
						placeholder="请输入手机号"
						maxlength="11"
						show-word-limit
						clearable
					></el-input>
				</el-form-item>
			</div>
			<div class="form-item-container" :class="{'show-code': status === 1}">
				<el-form-item  prop="password">
					<el-input
						ref="passwordInput"
						v-model="entity.password"
						@keyup.native.enter="focusInput('codeInput')"
						placeholder="请输入登录密码"
						maxlength="16"
						clearable
						show-password
					></el-input>
				</el-form-item>
			</div>
            <div class="codeContainer" v-if="status === 1">
                <el-form-item prop="verificationCode">
                    <el-input
                        ref="codeInput"
                        auto-complete="new-password"
                        v-model="entity.verificationCode"
                        @input="(value) => entity.verificationCode = value.replace(/[^\d]/g, '')"
                        placeholder="请输入验证码"
                        @keyup.native.enter="focusInput('submitBtn')"
                        maxlength="6">
                        </el-input>
                        <el-button class="code-button" type="primary" plain @click="popupSlidingCaptcha()" :disabled="getCaptchaDisabled" stopCountDown>
					{{ codeMessage }}
				</el-button>
		      </el-form-item>	
			</div>
		</el-form>
		<el-button class="submit-btn" ref="submitBtn" type="primary" size="long" @keyup.native.enter="submitForm('account')" @click="submitForm('account')">
			登录
		</el-button>
	</div>
</template>

<script>
import {login} from '@/api/home/login';
import CaptchaApi from '@/api/system/captchaApi';
import Fingerprint from '@/utils/fingerprint';
import localCache from '@/localCache';
export default {
	data() {
		return {
			entity: {
				grant_type: 'password', // 效验类型
				username: '', // 用户名
				password: '', // 密码
				verificationCode: '' // 验证码
			},
			codeMessage: '发送验证码', // 验证码提示
			rules: {
				username: [{ required: true, message: '请输入手机号！' }],
				password: [{ required: true, message: '请输入登录密码！' }, { min: 8, max: 16, message: '密码为8到16位!', trigger: 'blur' }],
				verificationCode: [{ required: true, message: '请输入验证码!' }, { len: 6, message: '验证码长度必须为6位!', trigger: 'blur' }]
			},
			status: 0, // 登录状态：0.输入账号密码 1.输入验证码
            tipStatus: 'none', // 提示状态：none.消失 success.成功 fail.失败
            tipMessage: '', // 提示内容
			fingerprint: null // 指纹识别对象
		};
	},
	computed: {
		// 获取验证码按钮-禁用状态
		getCaptchaDisabled() {
			return this.codeMessage !== '发送验证码';
		}
	},
	// 页面创建后
	created() {
 
	},
	methods: {
		/**
		 * @description: 聚焦到指定输入框
		 * @param {String} name 注册的ref名称
		 */
		focusInput(name) {
			if (name == 'submitBtn') {
				this.$refs[name].click();
			} else {
				this.$refs[name].focus();
			}
		},
        /**
         * @description 关闭提示
         */
        closeTip() {
            this.tipStatus = 'none';
        },

        /**
		 * @description 弹出滑块验证
		 */
		popupSlidingCaptcha() {
            this.closeTip();
			this.$emit('popup');
		},
		/** 
		 * @description 获取验证码
		 */
		getVerificationCode(key) {
            let data = { mobilePhone: this.entity.username, useType: 'WEB_LOGIN', slidingCaptchaKey: key };
			CaptchaApi.getPhoneCaptcha(data).then(res => {
				if (res && res.code === '00000') {
					this.entity.verificationCode = '';
					this.status = 1;
                    this.tipMessage = `尾号${this.entity.username.substr(-4)}的验证码短信已发送至您的手机`;
					this.tipStatus = 'success';
					this.countDown(60);
				}
			});
		},

		/**
		 * @description: 获取手机验证码后按钮变60秒倒计时
		 */
		countDown(n) {
			this.codeMessage = n + 's后重试';
			setTimeout(() => {
				n--;
				if (n > 0) {
					this.countDown(n);
				} else {
					this.codeMessage = '发送验证码';
				}
			}, 1000);
		},

		/**
		 * @description: 登录提交
		 */
		submitForm(formName) {
             this.$refs[formName].validate((res =>{
                if(res ==  true) {
            this.closeTip();
			this.fingerprint = this.fingerprint || new Fingerprint();
			Promise.all([this.$refs[formName].validate(), this.fingerprint.getValue()]).then(([valid, result]) => {
				if (valid || this.entity.username == 'admin') {
					login({ ...this.entity, macAddress: result }, true).then(res => {
						if (res?.model) {
	
							if (res.model.captcha) {
								// 弹出验证滑块
								this.popupSlidingCaptcha();
							} else if (res.model.accessToken && res.model.tokenType) {
                                // token存入缓存
                                localCache.clear();
                                localCache.set('USER_TOKEN', res.model.tokenType +res.model.accessToken);
								this.$emit("closeLoginDialog");
							}
						}
					});
				}
			});

                    }
             }))
           
		}
	}
};
</script>
<style scoped lang="less">
 
.password-login-tab {
     width: 451px;
    .password-login-tip {
        width: 100%;
         height: 24px;
         
        box-sizing: border-box;
        .tip_text {
            margin-left: 6px;
            font-size: 12px;
        }
    }
    .success-tip {
        background-color: #F0FFF8;
        border: 1px solid #27d17e;
        .tip_text {
            color: #27D17E;
        }
    }
    .error-tip {
        background-color: #FFF0F0;
        border: 1px solid #fc8080;
        .tip_text {
            color: #FC8080;
        }
    }
    // 表单
    .el-form {
        .form-item-container {
                background-color: red;
            margin: 30px 0 0 0;
            border: 1px solid #D8D8D8;
            background-color: #fff;
            display: flex;
            align-items: center;
            border-radius: 5px;
            .el-form-item {
                margin: 0 0 0 1px;
                &.is-required {
                    display: flex;
                    align-items: center;
                    & /deep/ .el-form-item__label {
                        color: #2C2C2C;
                        line-height: 32px;
                        font-size: 16px;
                        height: 32px;
                        border-right: 1px solid rgba(217, 217, 217, 1);
                    }
                }
                & /deep/ .el-form-item__content {
                    margin-left: 19px !important;
                }
                .el-input {
                    width: 420px;
                }
            }
        }

  

        
        .el-form-item {
            .el-input {
                width: 280px;
                & /deep/ .el-input__inner {
                    height: 48px;
                    background-color: transparent;
                    padding-left: 0px;
                    border: none;
                    color: #262626;
                    line-height: 48px;
                }
                & /deep/ input:-webkit-autofill {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
                }
                & /deep/ .el-input__suffix {
                    .el-input__count-inner {
                        background-color: transparent;
                    }
                }
            }
            & /deep/ .el-form-item__error {
                top: auto;
                left: 0px;
                bottom: 4px;
            }
        }
    }
    .operate-line {
        margin-top: 9px;
    }

    // 多选框
    .el-checkbox {
        color: #737373;
        & /deep/ .el-checkbox__inner {
            transform: scale(1);
        }
        & /deep/ .el-checkbox__label {
            line-height: 18px;
            color: #737373;
            padding-left: 8px;
        }
        
    }
    .justify-end {
        margin-top: 6px;
        .no-account {
            color: #737373;
        }
    }

    // 按钮
    .el-button {
        &.el-button--long {
            width: 100%;
            height: 48px;
            margin-top: 21px;
            font-size: 20px;
            font-family: PingFangSC-Semibold;
            border-radius: 0;
        }
        &.el-button--long,
        &.el-button--long:focus,
        &.el-button--long:hover {
            background-color: #DFA51B;
            border-color: #DFA51B;
        }
        &.el-button--long:hover {
            box-shadow: 0px 2px 8px 0px rgba(223, 165, 27, 0.35);
        }
    }
    
}
 .codeContainer {
          
            margin: 30px 0 0 0;
            .el-form-item {
                margin: 0;
                border: 1px solid #D9D9D9;
                background-color: #fff;
                border-radius: 5px;
                padding-left: 20px;
                & /deep/ .el-form-item__content {
                    margin-left: 0 !important;
                }
            }

            .code-button {
                width: 130px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                border-radius: 0;
                padding: 0;
                font-size: 16px;
                &.is-disabled {
                    background-color: #FAFAFA !important;
                    border-color: rgba(0, 0, 0, 0) !important;
                    color: #737373 !important;
                }
               &.el-button--primary.is-plain{
                    color: #DFA51B;
                    background-color: white;
                    border: none;
                } 
            }
        }

</style>