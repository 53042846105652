<template>
    <div class="desgin">
        <div class="content-center">
           <div class="title">专业服务推荐</div>
           <div class="card-bg">
                <desgin-card :item="item"  class="card" v-for="item in dataList" :key="item.code"  ></desgin-card>
           </div>
        </div>
    </div>
</template>

<script>
import DesginCard from './comments/DesginCard.vue'
 import {getspecialServeRecommend} from '@/api/home/home.js'
export default {
    components:{
        DesginCard,
    },
    data(){
        return{
            dataList:[],    
        }
    },
    created(){
        this.getRecommend()    
    },
    methods:{
        getRecommend(){
            getspecialServeRecommend().then(res =>{
                this.dataList = res.rows
            })
        }
    }
    
}
</script>

<style lang="less" scoped>
.desgin{
    height: 100%;
    margin: 0 auto;
   
 
    .content-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            padding: 40px 0;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: #2C2C2C;
            line-height: 35px; 
        }
        .card-bg{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

        }
        .card{
            margin-right: 17px;
        }
        .card:nth-child(4n){
            margin-right: 0px;
        }
    }
}
</style>