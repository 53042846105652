<template>
  <div class="message">
    <div class="content-center">
      <h2 class="message-title">ABOUT US</h2>
      <p class="message-remark">易隆创平台是为有能力获取新基建项目的企业或个人提供项目经营所需供给侧资源的平台，供给侧资源包括但不限于资质、业绩人员团队、供应商、资金等。</p>
      <p class="message-remark">作为国内信息化基础建设领域唯一的云企业平台，易隆创构建的是一个相互依靠的生态系统，为云企业做赋能和加持。</p>
      <div class="intro-container">
        <div class="intro-item" v-for="(item, index) in intros" :key="index">
          <img class="icon" :src="item.icon">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="remark">{{ item.remark }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      intros: [
        { title: '2007', remark: '公司成立时间', icon: require('@/assets/img/aboutUs/ic_message1.png') },
        { title: '500+', remark: '公司团队', icon: require('@/assets/img/aboutUs/ic_message2.png') },
        { title: '30+', remark: '全国分公司数量', icon: require('@/assets/img/aboutUs/ic_message3.png') },
        { title: '2000+', remark: '合作企业数量', icon: require('@/assets/img/aboutUs/ic_message4.png') },
        { title: '20+', remark: '行业经验', icon: require('@/assets/img/aboutUs/ic_message5.png') }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.message {
  padding: 55px 0 70px;
  background-color: #F9F9F9;
  .message-title {
    margin-bottom: 20px;
    color: #2C2C2C;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }
  .message-remark {
    color: #2C2C2C;
    font-size: 14px;
    line-height: 2;
    text-align: center;
  }
  .intro-container {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    .intro-item {
      display: flex;
      .icon {
        width: 45px;
        height: 45px;
        margin-right: 12px;
      }
      .content {
        .title {
          color: #2C2C2C;
          font-size: 36px;
          line-height: 45px;
        }
        .remark {
          margin-top: 10px;
          color: #666666;
          font-size: 18px;
        }
      }
    }
  }
}
</style>