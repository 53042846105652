<template>
<div>
  <div class="page-header">
      <div class="page-header__body">
        <div class="content-center">
          <div class="page-header__left">
            <img class="img-longstron" src="@/assets/img/home/longstron.png" alt="易隆创">
            <span class="welcome">欢迎来到易隆创平台</span>
          </div>
          <div class="page-header__right">
            <div class="link-item city"><img class="ic-location" src="@/assets/img/home/ic_location.png">{{location}}</div>
            <div class="link-item" @click="navClick('home')">首页</div>
            <div class="link-item" @click="navClick('aboutUs')">关于我们 </div>
            <div class="link-item" @click="goYlc()">易隆创平台</div>
            <div class="login-btn" v-if="$store.getters.currentUser == null" @click="showLoginDialog()">登录</div>
            <div class="user-box">
              <div class="user-container" v-if="$store.getters.currentUser">
                <div class="user">
                  <img v-if="$store.getters.currentUser.headPortrait" :src="$store.getters.currentUser.headPortrait" alt="">
                  <img v-else src="@/assets/img/home/logo.png" alt="">
                  <span class="user-name">{{$store.getters.currentUser.realname}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                </div>
                <div class="log-out" @click="handleCommand()">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="placeholder"></div>
    </div>
    <login-dialog  ref="loginRef"></login-dialog>
      <el-dialog
      :show-close = false
      :close-on-click-modal =false
        title="你确定要退出吗？"
        :visible.sync="dialogVisible"
        width="300px">
        <span>确定退出登录</span>
        <span slot="footer" class="dialog-footer">
          <div  class="btn cancle-btn" @click="showLog()">退 出</div>
          <div class="btn login-btn" @click="dialogVisible = false ,showLogOut = false">保持登录</div>

        </span>
  </el-dialog>
</div>


</template>

<script>
import LoginDialog from '../components/LoginDialog/index.vue';
// import {logout} from '@/api/home/login.js'
import localCache from '@/localCache';
import store  from "@/store/index.js";
import {logout} from '@/api/home/login.js';
export default {
  components:{
      LoginDialog,
  },
   data(){
    return{
      dialogVisible:false,
      showLogOut:false,
      location:'全国'
    }
   },
  created(){
    // this.getLocation()
  },
  methods: {
    navClick(type) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if (type == this.$route.name) {
        if (type == 'home') {
          this.$emit('refreshView')
        }
        return
      }
      this.$router.push('/' + type);
    },
    showLoginDialog(){
       this.showLogin()
    },
    goYlc(){
      const token = localCache.get("USER_TOKEN");
      this.$cookies.set('token', token, { expires: 1, domain:'.591ylc.com'});
      window.open(process.env.VUE_APP_URL + '#/dashboard')
    },
     handleCommand() {
        this.showLogOut = false
        this.dialogVisible = true

      },
      showLogin(){
      this.$refs.loginRef.dialogVisible = true;
    },
    showLog(){
      this.dialogVisible = false;
      store.dispatch('userClear');
      logout();
    },
    logoutAction(){
      this.showLogOut = true
    }

  }
};
</script>

<style lang="less" scoped>
.page-header {
  .page-header__body,
  .content-center,
  .placeholder {
    height: 56px;
  }

  .page-header__body {
    width: 100%;
    min-width: 1200px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .content-center {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .page-header__left {
        display: flex;
        align-items: center;

        .img-longstron {
          width: 154px;
          height: 37px;
        }

        .welcome {
          margin-left: 33px;
          color: #DFA51B;
          font-size: 16px;
          font-weight: bold;
          font-style: italic;
        }
      }

      .page-header__right {
        position: relative;
        display: flex;
        align-items: center;
        .link-item {

          margin-right: 75px;
          font-size: 14px;
          color: #333333;
          cursor: pointer;
          transform: all 0.5s;

          &.city {
            display: flex;
            align-items: center;

            .ic-location {
              width: 22px;
              height: 22px;
              margin-right: 6px;
            }
          }
        }
        .link-item:hover{
         background-color: #FaFafa;
          color: #DFA51B;
        }

        .login-btn {
          width: 93px;
          height: 38px;
          background-color: #DFA51B;
          border-radius: 19px;
          color: #ffffff;
          font-size: 16px;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
      }
    }
    .user-box {
      width: 101px;
      height: 30px;
      position: relative;
    }
    .user-container {
      position: absolute;
      top: 0;
      left: 0;
      &:hover .log-out {
        display: block;
      }

      .user{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        img{
          width: 30px;
          height: 30px;
          border-radius: 15px;
          border: 1px solid #DFA51B;
          box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        }
        .user-name{
          display: flex;
          flex-direction: row;
          padding-left: 10px;
          font-size: 14px;
          color: #2c2c2c;
          font-weight:400 ;
        }

      }
      .log-out{
        display: none;
        position: relative;
        height: 40px;
        width: 95px;
        margin-top: 20px;
        border-radius: 9px;
        font-size: 14px;
        color: #2c2c2c;
        text-align: center;
        line-height: 40px;
        font-weight: 500;
        background-color: white;
        cursor: pointer;
          &::after{
          position: absolute;
          content: '';
          top: -7px;
          right: 9px;

          border-bottom: 7px solid white;
          border-left:  4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
    }
  }

}
.el-dialog__body{
  padding: 0px;
}
.dialog-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn{
      width: 80px;
      height: 30px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }
    .cancle-btn{
      background-color: rgba(216, 216, 216, 1);
      color: rgba(142, 142, 142, 1);
      &:hover{
        background-color: rgba(227, 174, 49, 1);
        color: white;
      }
    }
    .login-btn{
      margin-left: 10px;
      background-color: rgba(227, 174, 49, 1);
      color: white;
    }

  }

  /deep/ .el-dialog__body{
    padding: 10px 20px;
  }
</style>
