<template>
  <div class="tab-supplier">
    <div class="enquiry">
      <div class="enquiry-form">
        <el-input
          class="enquiry-input"
          v-model="limit.fuzzyText"
          prefix-icon="el-icon-search"
          placeholder="请输入材料名称"
          @keydown.enter.native="getMaterialInquiry"
        ></el-input>
        <div class="enquiry-btn" @click="getMaterialInquiry">询价</div>
      </div>
      <div class="enquiry-table">
        <el-table :data="dataList" stripe height="436px">
          <el-table-column prop="name" label="材料名称" width="240"></el-table-column>
          <el-table-column prop="brand" label="品牌名称"></el-table-column>
          <el-table-column prop="model" label="规格型号"></el-table-column>
          <el-table-column label="价格">
            <template slot-scope="scope">
              <div><span class="price">{{ scope.row.currentPrice }}</span> / <span class="unit">{{ scope.row.measUnit }}</span></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="offer-flow">
      <div class="offer-flow-title">
        <img class="icon" src="@/assets/img/home/ic_rhombus.png">
        <span class="text">报价流程：</span>
      </div>
      <template v-for="(item, index) in flows">
        <div class="offer-flow-item" :key="item.title">
          <img class="icon" :src="item.src">
          <p class="title">{{ item.title }}</p>
        </div>
        <img class="icon-flow-arrow" src="@/assets/img/home/ic_offer_flow_arrow.png" :key="index">
      </template>
    </div>
  </div>
</template>

<script>
import { getMaterialInquiry } from "@/api/home/home.js";
import Loading from '@/utils/loading.js';
export default {
  data () {
    return {
      dataList: [],
      limit: {
        pageSize: 20,
        fuzzyText: ''
      },
      flows: [
        { title: '发布报价', src: require('@/assets/img/home/ic_offer_flow1.png') },
        { title: '报价审核', src: require('@/assets/img/home/ic_offer_flow2.png') },
        { title: '返回报价结果', src: require('@/assets/img/home/ic_offer_flow3.png') },
        { title: '签约合作', src: require('@/assets/img/home/ic_offer_flow4.png') }
      ]
    }
  },
  created(){
    this.getMaterialInquiry()
  },
  methods: {
    getMaterialInquiry() {
      Loading.open()
      getMaterialInquiry(this.limit).then(res => {
          this.dataList = res.rows;
          Loading.close()
        });
    }
  }
};
</script>

<style lang="less" scoped>
.tab-supplier {
  padding: 0 20px;
  background-color: #ffffff;
  box-shadow: -3px 1px 10px 2px rgba(0, 0, 0, 0.1);
  .enquiry {
    padding: 47px 150px 35px;
    .enquiry-form {
      display: flex;
      .enquiry-input {
        flex: 1;
        font-size: 24px;
        ::v-deep .el-input__inner {
          height: 66px;
          padding-left: 64px;
          border-radius: 0;
          &:focus {
            border-color: #EFCF4F;
          }
        }
        ::v-deep .el-input__icon {
          width: 60px;
          font-size: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .enquiry-btn {
        width: 146px;
        height: 66px;
        margin-left: 20px;
        background: linear-gradient(90deg, #EFCF4F, #DFA51B);
        color: #ffffff;
        font-size: 24px;
        line-height: 64px;
        text-align: center;
        cursor: pointer;
      }
      .enquiry-btn:active{
        color: #DFA51B;
      }
    }
    .enquiry-table {
      margin-top: 20px;
      .el-table {
        &::before {
          display: none;
        }
        ::v-deep .el-table__header .el-table__cell {
          height: 54px;
          background-color: #E3AE32;
          border: none;
          .cell {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        ::v-deep .el-table__body-wrapper {
          .el-table__row {
            background-color: #FDFAF3;
            color: #2c2c2c;
            &.el-table__row--striped {
              background-color: #FFF6DF;
            }
            .el-table__cell {
              background: none;
              border: none;
            }
          }
        }
        ::v-deep .el-table__empty-block {
          background-color: #FDFAF3;
        }
      }
      .price {
        color: #DFA51B;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
      }
    }
  }
  .offer-flow {
    padding: 40px 0 40px 35px;
    border-top: 1px solid rgba(216, 216, 216, 0.4);
    display: flex;
    align-items: center;
    .offer-flow-title {
      margin-right: 73px;
      display: flex;
      align-items: center;
      .icon {
        width: 19px;
        height: 19px;
        margin-right: 7px;
      }
      .text {
        color: #DFA51B;
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
      }
    }
    .offer-flow-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 78px;
        height: 78px;
      }
      .title {
        margin-top: 5px;
        color: #2C2C2C;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .icon-flow-arrow {
      width: 40px;
      height: 40px;
      margin: 0 40px;
      padding: 19px 0 44px;
      box-sizing: content-box;
      &:last-child {
        display: none;
      }
    }
  }
}
</style>