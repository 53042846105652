/**
 * @FileDescription: 登录 api
 * @Author: JGF
 * @Date: 2023-08-28
 * @LastEditors: JGF
 * @LastEditTime: 2023-08-28
 */
import axios from "../base/axios.js";
import localCache from "@/localCache/index.js";
const baseUrl = '/longstron/auth/oauth';
import Vue from 'vue'


/**
 * @description: 获取登录二维码
 */
export function getLoginQrCode() {
    return axios.get(baseUrl + "/qrCode/getQrCodeForLogin");

}

/**
 * @description: 获取登录二维码
 */
export function getQrCodeState(sessionId) {
    return axios.get(baseUrl + "/qrCode/" + sessionId + "/getQrCodeState");
}

/**
     * @description: 获取登录token
     */
export function getToken(data, ignoreChace = false) {
    const promise = new Promise(resolve => {
        if (!ignoreChace && localCache.get("USER_TOKEN")) {
            return resolve(localCache.get("USER_TOKEN"));
        }
        if (data.login_type == null) {
            data.login_type = "WEB_LOGIN_PASSWORD";
        }
        data.version = Vue.prototype.$version;
        axios.post(baseUrl + "/token", data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic bG9uZ3N0cm9uLWFkbWluOjEyMzQ1Ng==',
            }
        }).then(res => {
            if (res && res.code === "00000") {
                localCache.clear();
                localCache.set('USER_TOKEN', res.model.tokenType + res.model.accessToken);
                return resolve(res.model.tokenType + res.model.accessToken);
            } else {
                return resolve(null);
            }
        });
    });
    return promise;
}

/**
 * @description: 登录
 */
export function login(data, ignoreChace = false) {
    const promise = new Promise(resolve => {
        if (!ignoreChace && localCache.get("USER_TOKEN")) {
            return resolve(localCache.get("USER_TOKEN"));
        }
        if (data.login_type == null) {
            data.login_type = "WEB_LOGIN_PASSWORD";
        }
        data.version = Vue.prototype.$version;
        axios.post(baseUrl + "/login", data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic bG9uZ3N0cm9uLWFkbWluOjEyMzQ1Ng==',
            }
        }).then(res => {
            if (res && res.code === "00000") {
                localCache.clear();
                localCache.set('USER_TOKEN', res.model.tokenType + res.model.accessToken);
                return resolve(res);
            } else {
                return resolve(null);
            }
        });
    });
    return promise;
}
/**
 * @description: 验证token
 */
export function checkToken(data) {
    return axios.post(baseUrl + '/check_token', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded' // formData数据格式
        }
    });
}

/**
 * @description 退出登录
 */
export function logout() {
    return axios.delete(baseUrl + '/logout',{
    		headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': localCache.get("USER_TOKEN"),
          }
    		}).then(res => {
          if (res && res.code === "00000") {
            localCache.clear();
          } 
    	});
}

/**
 * @description 手机号登录
 * @param {Object} { mobilePhone: 手机号, verificationCode: 验证码 }
 */
export function loginForMobile(data) {
    return new Promise((resolve, reject) => {
        axios.post(baseUrl  + '/forMobile', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic bG9uZ3N0cm9uLWFkbWluOjEyMzQ1Ng==',
            }
        }).then(res => {
            if (res && res.code === "00000") {
                localCache.clear();
                localCache.set('USER_TOKEN', res.model.tokenType + res.model.accessToken);
                return resolve(res);
            } else {
                return resolve(null);
            }
        });
    })
}
