<template>
	<div class="qr-code-login">
        <div class="title">扫码登录</div>
		<div class=" image-container">
			<div class="qr-code-state-body" v-if="qrCode.qrCodeState != null && qrCode.qrCodeState != 'Init'">
				<!-- 蒙层 -->
				<div class="qr-code-state-modal"></div>
				
				<!-- 确定登录 -->
				<div class="qr-code-state scan-success" v-if="qrCode.qrCodeState == 'QrCodeLogin'">
					<el-result icon="success" title="确定登录" subTitle="登录成功,加载中...">
					</el-result>
				</div>
				
				<!-- 扫描成功 -->
				<div class="qr-code-state scan-success" v-if="qrCode.qrCodeState == 'ScanSuccess'">
					<el-result icon="warning" title="扫描成功" subTitle="请根据提示在APP端进行操作">
					</el-result>
				</div>

				<!-- 取消登录 -->
				<div class="qr-code-state cancel-login" v-if="qrCode.qrCodeState == 'CancelLogin'">
					<el-result icon="warning" title="取消登录" subTitle="本次二维码登录已结束">
						<template slot="extra">
							<el-button icon="el-icon-refresh-left" type="primary" size="medium" @click="refreshQrCode">刷新</el-button>
						</template>
					</el-result>
				</div>

				<!-- 二维码失效  -->
				<div class="qr-code-state invalid" v-if="qrCode.qrCodeState == 'Invalid'">
					<el-result icon="warning" title="二维码已失效" subTitle="请点击刷新重新获取">
						<template slot="extra">
							<el-button icon="el-icon-refresh-right" type="primary" size="medium" @click="refreshQrCode">刷新</el-button>
						</template>
					</el-result>
				</div>
			</div>
			<el-image :src="qrCode.qrCodeBase64Image" fit="fit" v-loading="qrCode.loading">
				<div slot="error" class="image-slot text-xsl"><i class="el-icon-picture-outline"></i></div>
			</el-image>
		</div>
		<div class="qr-code-login-tip">使用微信/易隆创APP扫码登录</div>
	</div>
</template>

<script>
import {getLoginQrCode,getQrCodeState} from '@/api/home/login.js';
 import localCache from '@/localCache';
export default {
	name: 'code-login',

	data: function() {
		return {
			qrCode: this.defaultQrCode()
		};
	},
	computed: {},
	// 页面创建后
	created() {
		this.init();
	},
	methods: {
		/**
		 * 初始化
		 * 每1000毫秒发送一次请求，总计时60秒
		 * 获取当前二维码处理状态：未扫描，已扫描，确定登录，取消登录状态
		 * 未扫描：页面不处理
		 * 已扫描：显示扫码成功
		 * 确定登录：直接登录
		 * 取消登录：显示取消登录，二维码失效，点击刷新重新获取
		 */
		init() {
			if (this.qrCode.timer != null) {
				this.stopInterval();
			}
			this.initTimer();
		},

		/**
		 * 默认实体类
		 */
		defaultQrCode() {
			return {
				id: null, // 二维码唯一标识
				sessionId: null, // 二维码sessionId
				qrCodeState: null, // 二维码状态:未扫描，已扫描，确定登录，取消登录状态
				qrCodeBase64Image: '', // 二维码显示地址
				invalidTime: 60, // 二维码失效时间60秒
				startIntervalTime: null, // 开始定时任务时间
				stopIntervalTime: null, // 结束定时任务时间
				timer: null // 定时器
			};
		},

		/**
		 * 初始化定时器
		 */
		initTimer() {
			// 恢复默认
			this.qrCode = this.defaultQrCode();
			// 启动定时器
			this.getLoginCode(true);
		},

		/**
		 * @description: 获取扫码登录二维码
		 * @param {Boolean} loading 是否单选加载
		 */
		getLoginCode() {
			getLoginQrCode().then(res => {
				if(res && res.code == '00000'){
					this.qrCode = Object.assign(this.qrCode, res.model);
					if(this.qrCode.id == null){
						// 二维码超时失效
						this.qrCodeInvalid();
					}else{
						if(this.isNotEmpty(this.qrCode.qrCodeBase64Image)){
							// 启动定时器
							this.startInterval();
						}
					}
				}
			});
		},

		/**
		 * 向服务端请求二维码状态
		 */
		getCodeState() {
			if(this.qrCode.id == null){
				return;
			}
			getQrCodeState(this.qrCode.id).then(res => {
				if(res && res.code == '00000'){
					this.qrCode.qrCodeState = res.model.qrCodeState;
					this.qrCode.id = res.model.id;
					this.qrCode.effectiveTime = res.model.effectiveTime / 1000;

					if(this.qrCode.qrCodeState == 'ScanSuccess'){
						this.qrCodeScanSuccess();
						
					}else if(this.qrCode.qrCodeState == 'CancelLogin'){
						this.qrCodeCancelLogin();
						
					}else if(this.qrCode.qrCodeState == 'QrCodeLogin'){
						this.qrCode.accessToken = res.model.accessToken;
						this.qrCodeLogin();
						
					}else if(this.qrCode.qrCodeState == 'Invalid' || this.qrCode.id == null){
						this.qrCodeInvalid();
					}
				}
			});
		},

		/**
		 * 刷新二维码
		 */
		refreshQrCode() {
			this.initTimer();
		},

		/**
		 * 二维码扫码成功
		 */
		qrCodeScanSuccess() {
			this.qrCode.qrCodeState = 'ScanSuccess';
		},
		
		/**
		 * 二维码超时失效
		 */
		qrCodeInvalid() {
			this.qrCode.qrCodeState = 'Invalid';
			// 停止定时器
			this.stopInterval();
		},
		
		/**
		 * 二维码取消登录
		 */
		qrCodeCancelLogin() {
			this.qrCode.qrCodeState = 'CancelLogin';
			// 停止定时器
			this.stopInterval();
		},

		/**
		 * 二维码确定登录
		 */
		qrCodeLogin() {
			this.qrCode.qrCodeState = 'init';
			// 停止定时器
			this.stopInterval();
			
		// token存入缓存
			console.log('USER_TOKEN', localCache.get("USER_TOKEN"))
			localCache.remove('USER_TOKEN');
			console.log('USER_TOKEN', localCache.get("USER_TOKEN"))
			localCache.set('USER_TOKEN', this.qrCode.accessToken);
			console.log('USER_TOKEN', localCache.get("USER_TOKEN"))
			this.$emit("closeLoginDialog");
			 
		},

		/**
		 * 启动定时器
		 */
		startInterval() {
			console.log('定时任务【开启】');
			this.qrCode.startIntervalTime = new Date().getTime();
			let number = 0;
			this.qrCode.timer = setInterval(() => {
				number++;
				// 向服务端请求二维码状态
				this.getCodeState();
				// 二维码失效
				if (number == this.qrCode.invalidTime) {
					// 二维码超时失效
					this.qrCodeInvalid();
				}
			}, 2000);
		},

		/**
		 * 停止定时器
		 */
		stopInterval() {
			clearInterval(this.qrCode.timer);
			this.qrCode.stopIntervalTime = new Date().getTime();
			console.log('定时任务【关闭】【用时：' + this.getConsumeTime(this.qrCode.startIntervalTime, this.qrCode.stopIntervalTime) + '秒】');
		},
		/**
		 * 获取消耗时间
		 */
		getConsumeTime(startTime, stopTime) {
			if (startTime == null || stopTime == null) {
				return -1;
			}
			let consumeTime = stopTime - startTime;
			return consumeTime / 1000;
		},
		isNotEmpty(value) {
			return !this.isEmpty(value);
		},
		isEmpty(value) {
			if (value == null) {
				return true;
			}
			let type = Object.prototype.toString.call(value);
			switch (type) {
				case '[object String]':
					return value == "";
				case '[object Number]':
					return isNaN(value);
				case '[object Array]':
					return value.length == 0
				case '[object Object]':
					return Object.keys(value).length == 0
				case '[object Date]':
					return isNaN(value.getTime())
				case '[object Undefined]':
				case '[object Null]':
					return true
				default:
					return false;
			}
		}
	}
};
</script>

<style scoped lang="less">
.qr-code-login {
	position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-top: 20px;
	& /deep/ .el-image {
		width: 262px;
		height: 262px;
		.image-slot {
			background-color: rgba(255, 255, 255, 0.05);
		}
		.el-loading-mask {
			background-color: transparent;
		}
		&.el-loading-parent--relative {
			.image-slot > .el-icon-picture-outline {
				display: none;
			}
		}
	}
 
	.qr-code-state-body {
		position: absolute;
		z-index: 5;
		top: 2px;
		left: 2px;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		.qr-code-state-modal {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.8);
		}

		.qr-code-state {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 7;

			& /deep/ .el-result {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0;
				height: 100%;
				p {
					color: white;
				}
				.el-result__title {
					margin-top: 11px;
					p {
						font-size: 18px;
						font-family: PingFangSC-Medium;
					}
				}
				.el-result__subtitle {
					margin-top: 4px;
				}
				.el-result__extra {
					margin-top: 13px;
				}
				img {
					width: 65px;
					height: 65px;
				}
			}
		}
	}


	.qr-code-login-tip {
		margin-top: 17px;
		font-size: 16px;
		color: #666;
        font-weight: 400;
	}
    .title{
	  margin-bottom: 28px;
        color: #2C2C2C;
        font-size: 20px;
        font-weight: 400;
    }
}
</style>
