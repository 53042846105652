<!-- 
* @FileDescription: 首页
* @Author: zhuangjinshan
* @Date: 2023-08-17
* @LastEditors: zhuangjinshan
* @LastEditTime: 2023-08-17
-->
<template>
  <div class="page">
    <!-- 顶部导航 -->
    <PageHeader  @refreshView='refreshView()'></PageHeader>
    <!-- 轮播图 -->
    <HomeCarousel></HomeCarousel>
    <!-- 同命运，共富裕 -->
    <MainTabs></MainTabs>
    <!-- 项目案例 -->
    <project-case></project-case>
    <!-- 供应商服务 -->
    <supply-serve></supply-serve>
    <!-- 专业推荐设计 -->
    <desgin-recommend></desgin-recommend>
    <solution-view></solution-view>
    <!-- 底部 -->
    <PageFooter></PageFooter> 
  
    <!-- 客服二维码 -->
    <aside-code></aside-code>
  </div>
</template>

<script>
import PageHeader from "./components/PageHeader.vue";
import PageFooter from "./components/PageFooter.vue";
import HomeCarousel from "./components/HomeCarousel.vue";
import MainTabs from "./components/MainTabs/index.vue";
import solutionView from './components/solutionView.vue';
import ProjectCase  from './components/ProjectCash/index.vue'; 
import supplyServe from './components/supplyServe.vue';
import desginRecommend from './components/desginRecommend/index.vue';
import AsideCode from './components/AsideCode.vue';

export default {
  components: {
    PageHeader,
    PageFooter,
    HomeCarousel,
    MainTabs,
    solutionView,
  
    ProjectCase,
    supplyServe,
    desginRecommend,
    AsideCode,
  },
  methods:{
    refreshView(){
      window.location.reload()
    }
  }
 
};
</script>

<style lang="less" scoped>
.page {
  position: relative;
  ::v-deep .module-title {
    line-height: 90px;
    color: #2C2C2C;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  ::v-deep .module-remark {
    color: #9E9E9E;
    font-size: 16px;
    text-align: center;
  }
}
</style>