<template>
<div>
        <div class="solution">
                 <div class="solu-title">
                解决<span style="color:#DFA51B">方案</span>
            </div>
            <div class="solu-mean">
                多场景、多行业解决方案 满足企业多元需求
            </div>
        <div class="content-view">
   
            <div class="solu-content">
                <div class="list-content">
                    <div class="card" v-for="item in dataList" :key="item.programmeList.id">
                    <img src="@/assets/img/home/ic_data_soultion.png">
                        <div class="card-right">
                            <div class="solu-card-title">{{item.programmeList.name}}</div>
                            <div class="file-down"  >
                                <a  class="card-mean" @click="showFile(item.programmeList.detailPageUrl)" >
                                    <span>文件预览</span>
                                    <img src="@/assets/img/home/ic_down.png">
                                </a>
                                 
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <login-dialog  ref="loginRef"></login-dialog>
</div>
</template>
<script>
 
import { getProgramme } from "@/api/home/home.js";
import localCache from '@/localCache';
import LoginDialog from '../components/LoginDialog/index.vue';
export default  {
    name:'solution-view',
  components:{
      LoginDialog,
  },
   data () {
    return {
      dataList: [],
      token: ''
    }
  },
  created () {
    this.load();
  },
  methods: {
    load() {
      getProgramme({ pageSize: 2 ,programmeType:["SOLUTION"]}).then(res => {
        if (res.rows.length > 2) {
             this.dataList = res.rows.slice(0,2);
        }else{
             this.dataList = res.rows;
        }
       
      });
    },
    showFile(path){
        this.token = localCache.get("USER_TOKEN");
        if (this.token) {
             window.open(path)
        }else{
           this.$confirm('未登录不可预览文件, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.showLogin()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消登录'
          });          
        });
        }
       
    },
    showLogin(){
      this.$refs.loginRef.dialogVisible = true; 
    },
  

  }
    
}
</script>

<style scoped lang='less'>
.solution{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:40px;
        .solu-title{
        font-size: 24px;
        color: #333;
        text-align: center;
        font-weight: 700;
    }
    .solu-mean{
        margin-top: 24px;
        font-size: 18px;
        color: #9e9e9e;
        text-align: center; 
        font-weight: 500;
    }
    .content-view{
        min-width: 1448px;
        height: 100%;
        margin: 0 auto;
    }

    .solu-content{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 124px;
        .list-content{
          display: flex;  
          flex-flow: column;
          align-items: flex-start;
          width: 434px;
          height:308px;
          background-color: #FEFEFD;
          .card{
            width: 434px;
            height: 50%;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            background-image: url("@/assets/img/home/ic_soultion_bg.png");
            background-size: cover;
            background-position: center;
          
             img{
                width: 96px;
                margin: 20px 21px 0 25px;
             }

             .solu-card-title{
                line-height: 30px;
                font-size: 20px;
                color: #333;
                font-weight: 700;
             }
             .card-mean{
                display: flex;
                flex-direction:row ;
                justify-content: start;
                align-items: center;
                text-decoration: none;
                line-height: 40px;
                font-size: 16px;
                color: #9E9E9E;
                cursor: pointer;
                img{
                    padding-bottom: 15px;
                    width: 27px;
                    margin-left: 3px;
                }
             }
             .file-down{
                display: flex;
                flex-direction:row;
                align-items: center;
             }
              

          }
        }

    }
    .solu-content::after{
        content: '';
        background-image: url("@/assets/img/home/ic_soultion_partner.png") ;
        background-repeat:no-repeat ;
        background-size: 737px 737px;
        width: 737px;
        height: 737px;
    }


}
</style>
