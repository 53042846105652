import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters';
import createPersistedstate from 'vuex-persistedstate'

// modules
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    
  },
  getters,
   
  modules: {
    user
  },

  plugins: [
    createPersistedstate({
      storage: window.localStorage, // 存储方式
      key: 'user', // 存储键名
      paths: ['user'] // 缓存模块
    })
  ]
})
