<template>
	<div class="phone-login-tab">
        <div class="phone-login-tip " :class="`${tipStatus}-tip`">
            <div class="">
                <span class="tip_text">{{tipMessage}}</span>
            </div>
            <div @click="closeTip" class="cursor-pointer"></div>
        </div>
		<el-form ref="account" :model="entity" :rules="rules" label-width="86px" label-position="left" hide-required-asterisk>
			<div class="form-item-container">
			
				<el-form-item  prop="mobilePhone">
					<el-input
						ref="mobilePhoneInput"
						v-model="entity.mobilePhone"
						@keyup.native.enter="focusInput('passwordInput')"
						placeholder="请输入手机号"
						maxlength="11"
						show-word-limit
						clearable
					></el-input>
				</el-form-item>
			</div>
            <div class="code-container">
                <div class="form-item-container">
                    <el-form-item prop="verificationCode">
                        <el-input
                            ref="codeInput"
                            auto-complete="new-password"
                            v-model="entity.verificationCode"
                            @input="(value) => entity.verificationCode = value.replace(/[^\d]/g, '')"
                            placeholder="请输入验证码"
                            @keyup.native.enter="focusInput('submitBtn')"
                            maxlength="6"
                        ></el-input>
                         <el-button class="code-button" type="primary" plain @click="popupSlidingCaptcha()" :disabled="getCaptchaDisabled" stopCountDown>
                    {{ codeMessage }}
                </el-button>
                    </el-form-item>
                </div>
               
            </div>
		</el-form>
		<el-button class="submit-btn" ref="submitBtn" type="primary" size="long" @keyup.native.enter="submitForm('account')" @click="submitForm('account')">
			登录
		</el-button>
	</div>
</template>

<script>
import {loginForMobile} from '@/api/home/login';
import CaptchaApi from '@/api/system/captchaApi';
import localCache from '@/localCache';

export default {
	data() {
		return {
			entity: {
				mobilePhone: '', // 用户名
				verificationCode: '' // 验证码
			},
			isRemember: false, // 记住账号
			codeMessage: '获取验证码', // 验证码提示
			rules: {
				mobilePhone: [
                    { required: true, message: '请输入手机号！' },
                    {
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!/^1[3-9]\d{9}$/.test(value)) {
                                callback('手机号格式不正确!');
                            } else {
                                callback();
                            }
                        }
                    }
                ],
				verificationCode: [{ required: true, message: '请输入验证码!' }, { len: 6, message: '验证码长度必须为6位!', trigger: 'blur' }]
			},
            tipStatus: 'none', // 提示状态：none.消失 success.成功 fail.失败
            tipMessage: '' // 提示内容
		};
	},
	computed: {
		// 获取验证码按钮-禁用状态
		getCaptchaDisabled() {
			return this.codeMessage !== '获取验证码';
		}
	},
 
	methods: {
		/**
		 * @description: 聚焦到指定输入框
		 * @param {String} name 注册的ref名称
		 */
		focusInput(name) {
			if (name == 'submitBtn') {
				this.$refs[name].click();
			} else {
				this.$refs[name].focus();
			}
		},


        /**
         * @description 关闭提示
         */
        closeTip() {
            this.tipStatus = 'none';
        },

        /**
		 * @description 弹出滑块验证
		 */
		popupSlidingCaptcha() {
            console.log('first')
            this.$refs['account'].validateField('mobilePhone', error => {
                console.log('AAA')
                if (error) {
                    this.$message.error(error);
                } else {
                    this.closeTip();
                    this.$emit('popup');
                }
            });
		},

		/**
		 * @description 获取验证码
		 */
		getVerificationCode(key) {
            let data = { mobilePhone: this.entity.mobilePhone, useType: 'MOBILE_LOGIN', slidingCaptchaKey: key };
                CaptchaApi.getPhoneCaptcha(data).then(res => {
				if (res && res.code === '00000') {
					this.entity.verificationCode = '';
                    this.tipMessage = '短信验证码已发送，可能会有延后，请耐心等待';
					this.tipStatus = 'success';
					this.countDown(60);
				}
			});
		},

		/**
		 * @description: 获取手机验证码后按钮变60秒倒计时
		 */
		countDown(n) {
			this.codeMessage = n + 's后重试';
			setTimeout(() => {
				n--;
				if (n > 0) {
					this.countDown(n);
				} else {
					this.codeMessage = '获取验证码';
				}
			}, 1000);
		},

		/**
		 * @description: 登录提交
		 */
		submitForm(formName) {
            this.closeTip();
            this.$refs[formName].validate((valid=>{
                 if (valid) {
                    loginForMobile(this.entity).then(res => {
						if (res?.model && res.model.accessToken && res.model.tokenType) {
                            // token存入缓存
                            localCache.clear();
                            localCache.set('USER_TOKEN', res.model.tokenType +res.model.accessToken);
                            this.$emit("closeLoginDialog");
						}
					});
                 }
            }))
			// this.$refs[formName].validate().then(valid => {
			// 	if (valid) {
					
			// 	}
			// });
		}
	}
};
</script>
<style scoped lang="less">
.phone-login-tab {
    width: 451px;
    .phone-login-tip {
        width: 100%;
        height: 24px;
        padding: 0 13px;
        box-sizing: border-box;
        .tip_text {
            margin-left: 6px;
            font-size: 12px;
        }
    }
    .success-tip {
        background-color: #F0FFF8;
        border: 1px solid #27d17e;
        .tip_text {
            color: #27D17E;
        }
    }
    .error-tip {
        background-color: #FFF0F0;
        border: 1px solid #fc8080;
        .tip_text {
            color: #FC8080;
        }
    }
    .none-tip {
        visibility: hidden;
    }
    // 表单
    .el-form {
        .form-item-container {
           
            margin: 0px 0 0 0;
            border: 1px solid #D8D8D8;
            background-color: #fff;
           
            display: flex;
            align-items: center;
            border-radius: 5px;
            .el-form-item {
                margin: 0 0 0 1px;
                &.is-required {
                    display: flex;
                    align-items: center;
                    & /deep/ .el-form-item__label {
                        color: #2C2C2C;
                        line-height: 32px;
                        font-size: 16px;
                        height: 32px;
                        border-right: 1px solid rgba(217, 217, 217, 1);
                    }
                }
                & /deep/ .el-form-item__content {
                    margin-left: 19px !important;
                }
                .el-input {
                    width: 420px;
                }
            }
        }

        .code-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin: 30px 0;
            .form-item-container {
                .el-input {
                    width: 294px;
                }
            }
            .code-button {
                width: 120px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-radius: 0;
                padding: 0;
                margin-right: 10px;
                font-size: 16px;
                &.is-disabled {
                    background-color: #FAFAFA !important;
                    border-color: rgba(0, 0, 0, 0) !important;
                    color: #737373 !important;
                }
               &.el-button--primary.is-plain{
                    color: #DFA51B;
                    background-color: white;
                    border: none;
                } 
            }
        }

        .el-form-item {
            .el-input {
                width: 270px;
                & /deep/ .el-input__inner {
                    height: 48px;
                    background-color: transparent;
                    border: none;
                    color: #262626;
                    padding-left: 0px;
                    line-height: 48px;
                }
                & /deep/ input:-webkit-autofill {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
                }
                & /deep/ .el-input__suffix {
                    .el-input__count-inner {
                        background-color: transparent;
                    }
                }
            }
            & /deep/ .el-form-item__error {
                top: auto;
                bottom: 4px;
            }
        }
    }

    // 按钮
    .el-button {
        &.el-button--long {
            width: 100%;
            height: 48px;
            margin-top: 21px;
            font-size: 20px;
            font-family: PingFangSC-Semibold;
            border-radius: 0;
        }
        &.el-button--long,
        &.el-button--long:focus,
        &.el-button--long:hover {
            background-color: #DFA51B;
            border-color: #DFA51B;
        }
       
      
    }

    .el-input__inner{
        margin-left: 0;
    }

}
</style>