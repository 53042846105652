<template>
<div class="content">
            <div class="custom-serve cloumn-dir" @mouseover="connectService()" @mouseleave="hiddenCustom()" >
                <img src="@/assets/img/home/customer.png">
                <div v-if="isShowCustom"  class="show_custom"> 
                    <div class="cus-title">客服电话</div>    
                    <span class="phone"><img src="@/assets/img/home/phone.png">400-166-166</span>
                    <div class="serve-time">服务时间：9:00 - 18:00</div>  
                </div>
            </div>
            <div class="code cloumn-dir">
                <div class="card cloumn-dir" @mouseleave="hiddenCode()"  @mouseover="showCode(0)">
                    <img src="@/assets/img/home/app_code.png">
                    <span class="title">APP下载</span>
                </div>
                <div class="card cloumn-dir" @mouseleave="hiddenCode()" @mouseover="showCode(1)">
                    <img src="@/assets/img/home/wx-code.png">
                    <span class="title">小程序码</span>
                </div>
                <div class="card cloumn-dir" @mouseleave="hiddenCode()" @mouseover="showCode(2)">
                    <img src="@/assets/img/home/douyin_code.png">
                    <span class="title">抖音码</span>
                </div>
                <div class="to-top cloumn-dir" @click="goTop()">
                    <img src="@/assets/img/home/top.png">
                    <span class="title">TOP</span>
                </div>
                <div v-if="isShowCode" :style={top} class="show_code cloumn-dir">
                    <img :src="codeList[current].img()">
                    <span class="title">{{codeList[current].title}}</span>
                </div>

            </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            top:25 +'px',
            isShowCode:false,
           isShowCustom:false,
            current:0,
            codeList:[{
                title:'APP下载',
                img:() => require('@/assets/img/home/app_code.png')
            },{
                title:'小程序码',
                img: () => require('@/assets/img/home/wx-code.png')
            },{
                title:'抖音码',
                img:() => require('@/assets/img/home/douyin_code.png')
            }
            ]
        }
    },
    methods:{
        showCode(index){
            this.isShowCode = true
            this.current = index
            switch (index) {
                case 0:
                    this.top = '25px'
                    break;
                case 1:
                    this.top = '85px'
                    break;
                case 2:
                    this.top = '145px'
                    break;
                default:
                    break;
            }
        },
        hiddenCode(){
            this.isShowCode = false
        },
        connectService(){
            this.isShowCustom = true
        }, 
        hiddenCustom(){
            this.isShowCustom = false
        } ,
        goTop(){
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
  
    }
}
</script>
<style lang="less" scoped>
    .content{
    position: fixed;
    top: 300px;
    right: 0px;
    width: 100px;
    height: 300px;
    z-index: 1000;
     
     .custom-serve{
        padding-top: 15px;
        width: 56px;
        height: 58px;
        background: linear-gradient(360deg, #FFFFFF 0%, #FFF4D9 45%, #FFE19C 100%);
        box-shadow: 0px 4px 10px 0px rgba(227,174,50,0.2);
        border-radius: 208px 208px 208px 208px;
        opacity: 1;
     }
     .code{
        position: relative;
        margin-top: 21px;
        padding-top: 18px;
        width: 56px;
        height: 294px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        border-radius: 28px ;

        .card{
            margin-bottom: 10px;
            animation: color 0.5s;
             color: #404040;
            img{
                width:38px;
            }
            .title{
                height: 22px;
                font-size: 12px;
                font-weight: 400;
               
                line-height: 22px;  
            }
        }
        .card:hover{
            color: #FFA52B;
        }
        .to-top{
             margin-bottom: 5px;
            img{
                width:16px;
            }
            .title{
                height: 22px;
                font-size: 12px;
                font-weight: 400;
                color: #404040;
                line-height: 22px;  
            }
        }
     }
     .cloumn-dir{
        display: flex;
        flex-direction: column;
        align-items: center;
     }
     .show_code{
        position: absolute;
        padding-top: 10px;
        right: 70px;
        width: 140px;
        height: 164px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        border-radius: 5px;
         &::after{
            position: absolute;
            content: '';
             border-left: 6px solid white;
             border-top: 4px solid transparent;
             border-bottom: 4px solid transparent;
             top: 21px;
             right: -4px;
        }
        img{
            width: 123px;
            height: 123px;
             
        }
        .title{
            height: 30px;
            font-size: 12px;
            font-weight: 400;
            color: #404040;
            line-height: 30px;  
        }
     }

.show_custom{
    position: absolute;
    top: -10%;
    right: 110px;
    width: 206px;
    height: 130px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
    opacity: 1;
    border-radius: 4px;
    border: 1px solid #E3AE32;
        .cus-title{
            width: 204px;
            height: 45px;
            background: #E3AE32;
            opacity: 1;
            border: 1px solid #E3AE32;
            color: white;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 45px;
            text-align: center;
        }
        .phone{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 12px;
            font-size: 16px;
            font-weight: 700;
            color: #E3AE32;
            img{
                margin-right: 7px;
            } 
        }
        .serve-time{
            font-size: 12px;
            margin-top: 12px;
            text-align: center;
            font-weight: 400;
            color: #9E9E9E;
            line-height: 22px;
        }

        &::before{
            position: absolute;
            content: '';
             border-left: 6px solid #E3AE32;
             border-top: 4px solid transparent;
             border-bottom: 4px solid transparent;
            top: 50px;
            right: -6px;
        }
           &::after{
            position: absolute;
            content: '';
             border-left: 6px solid white;
             border-top: 4px solid transparent;
             border-bottom: 4px solid transparent;
             top: 50px;
             right: -4px;
        }
}
}

</style>