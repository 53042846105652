<template>
  <div class="page">
    <!-- 顶部导航 -->
    <PageHeader></PageHeader>
    <!-- 轮播图 -->
    <HomeCarousel></HomeCarousel>
    <!-- 信息 -->
    <Message></Message>
    <!-- 时间线 -->
    <TimeLine></TimeLine>
    <!-- 底部 -->
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageHeader from "../home/components/PageHeader.vue";
import PageFooter from "../home/components/PageFooter.vue";
import HomeCarousel from "../home/components/HomeCarousel.vue";
import Message from "./components/Message.vue";
import TimeLine from "./components/TimeLine.vue";
export default {
  components: {
    PageHeader,
    PageFooter,
    HomeCarousel,
    Message,
    TimeLine
  }
};
</script>

<style lang="less" scoped>

</style>