import { render, staticRenderFns } from "./policy.vue?vue&type=template&id=43893018&scoped=true&"
var script = {}
import style0 from "./policy.vue?vue&type=style&index=0&id=43893018&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43893018",
  null
  
)

export default component.exports