/**
 * @FileDescription: 首页api
 * @Author: zhuangjinshan
 * @Date: 2023-08-17
 * @LastEditors: zhuangjinshan
 * @LastEditTime: 2023-08-17
 */
import axios from "../base/axios.js";

const baseUrl = '/longstron/dashboard/info';

export function getListHotService() {
  return axios.post(baseUrl + '/listHotService');
}

export function getMaterialInquiry(limit) {
  return axios.post(baseUrl + '/material/inquiry/bom/queryLimit', limit);
}

export function getProjectCases(limit) {
  return axios.post(baseUrl + '/project/cases/queryLimit', limit);
}

export function getProgramme(limit) {
  return axios.post(baseUrl + '/programme/queryLimit', limit);
}

export function getListBidService() {
  return axios.post(baseUrl + '/listBidService');
}

export function getListFinanceService() {
  return axios.post(baseUrl + '/listFinanceService');
}

export function getSupplyServe() {
  return axios.post(baseUrl + '/supplier/countSuppliser');
}
// 服务人员推荐 
export function getServeRecommend(code){
  return axios.get(baseUrl + '/listByServiceCode/' + code);
}

export function getspecialServeRecommend() {
  return axios.post(baseUrl + '/listServiceRecommendation');
}
// 获取个人信息 
export function getCurrentUser(){
   return axios.get("/longstron/system/users/getCurrentUser");
}