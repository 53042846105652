/**
 * @FileDescription: 用户数据
 * @Author: zhuangjinshan
 * @Date: 2023-08-16
 * @LastEditors: zhuangjinshan
 * @LastEditTime: 2023-08-16
 */
export default {
  state: {
    currentUser: null
  },
  getters: {
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    SET_CURRENT_USER: (state, currentUser) => {
      state.currentUser = currentUser;
    },
    REMOVE_DATA: (state, prop) => {
      state[prop] = null;
    }
  },
  actions: {
    setToken(context, payload) {
      context.commit('setToken', payload);
    },
    setCurrentUser({
      commit
    }, currentUser) {
      commit('SET_CURRENT_USER', currentUser);
    },
    userClear({ commit }) {
      commit('REMOVE_DATA', 'currentUser');
    },
  
  }
}