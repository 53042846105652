import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home.vue'
import aboutUs from '../views/aboutUs/aboutUs.vue'
import servePolicy from '../views/servePolicy/serve.vue'
import policy from '../views/servePolicy/policy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },{
    path:'/servePolicy/serve',
    name:'servePolicy',
    component:servePolicy
  }
  , {
    path: '/servePolicy/policy',
    name: 'policy',
    component: policy
  }
]

const router = new VueRouter({
  routes
})

export default router
