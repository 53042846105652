/**
 * @FileDescription: ElementUI Loading重新封装
 * @Author: zhuangjinshan
 * @Date: 2023-08-17
 * @LastEditors: zhuangjinshan
 * @LastEditTime: 2023-08-17
 */
import { Loading } from 'element-ui';

let loadingInstance = null;
function open() {
  loadingInstance = Loading.service({
    fullscreen: true,
      lock: true,
      background: 'rgba(0, 0, 0, 0.15)',
    customClass: 'global-loading'
  });
}

function close() {
  loadingInstance.close();
}

export default {
  open,
  close
}