<template>
  <div class="platform-support">
    <img class="ic-support" src="@/assets/img/home/ic_platform_support1.png" alt="平台保障">
    <div class="support-right">
      <div class="support-item" v-for="(item, index) in supports" :key="index">
        <img class="ic-support-item" :src="item.src">
        <div class="support-item-content">
          <div class="support-item-title">{{ item.title }}</div>
          <div class="support-item-remark">{{ item.remark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      supports: [
        { title: '商家实名认证', remark: '服务商100%实名认证', src: require('@/assets/img/home/ic_platform_support2.png') },
        { title: '服务有标准', remark: '按标验收，服务保障', src: require('@/assets/img/home/ic_platform_support3.png') },
        { title: '资金更安全', remark: '平台担保交易，验收后付款', src: require('@/assets/img/home/ic_platform_support4.png') },
        { title: '流程审批快', remark: '自主审批，时效性强', src: require('@/assets/img/home/ic_platform_support5.png') }
      ]
    }
  }
};
</script>

<style lang="less" scoped>
.platform-support {
  height: 108px;
  padding: 0 29px 0 17px;
  background-image: url("~@/assets/img/home/bg_platform_support.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  .ic-support {
    width: 115px;
    height: 86px;
    margin-right: 22px;
  }
  .support-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .support-item {
      display: flex;
      align-items: center;
      .ic-support-item {
        width: 71px;
        height: 71px;
        margin-right: 5px;
      }
      .support-item-content {
        .support-item-title {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .support-item-remark {
          margin-top: 8px;
          color: #333333;
          font-size: 14px;
        }
      }
    }
  }
}
</style>