<template>
    <div class="bg">
        <div class="top">
            <div class="pr-title pr-text">{{item.name}}</div>
            <span class="pr-note">{{item.biddingPlatform}}</span>
        </div>
        <div class="pr-content pr-text">{{item.partA}}</div>
        <div class="bottom">
            <span class="money">{{item.amountLabel.substring(0, item.amountLabel.length -2)}}<span class="money-text">万元</span></span>
            <span class="adrress">{{item.provinceLabel + "•"+ item.cityLabel}}</span>
        </div>
        <div class="cover"></div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {};
            }

        }
    }
}
</script>

<style lang="less" scoped>
    .bg{
        position: relative;
        width: 287px;
        height: 299px;
        background-color: white;
        box-shadow: 0px 10px 19px 0px rgba(0,0,0,0.03);
        border-bottom:10px solid rgba(0,0,0,0.01) ;
       
    .top{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: 20px;
        background-image: url("@/assets/img/home/pr-top-card.png");
        width: 287px;
        height: 169px;
        padding: 25px 20px 0px 18px;
        .pr-title{
            margin-bottom: 25px;
            font-size: 18px;
            line-height: 26px;
        }
        .pr-note{
            position: absolute;
            bottom: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
        }
    }
        .pr-content{
            font-size: 16px;
              padding: 0 10px 0 20px;
              line-height: 23px;
         }
       .pr-text{
            font-weight: 500;
            color: #2C2C2C;
       }
       .bottom{
        position: absolute;
        bottom: 30px;
        left: 20px;
        display: flex;
        width: 257px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .money{
            font-size: 24px;
            font-weight: 700;
            color: #DFA51B;
            line-height: 26px;
            min-width: 110px;
            .money-text{
                font-size: 15px;
                color: #666;
            }
        }
        .adrress{
            font-size: 14px;
            color: #9E9E9E;
        }
       }

    }
    .cover {
        position: absolute;
        top: 0;
       width: 100%;
       height: 100%;
    }
    .cover:hover{
        background-color: rgba(0,0,0,0.05);
    }
</style> 