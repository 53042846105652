<template>
  <div class="find-project">
    <div class="project-item" v-for="item in dataList" :key="item.id">
      <div class="dot"></div>
      <div class="status">招标中</div>
      <div class="title">{{ item.name }}</div>
      <div class="price-unit">
        <span class="price">{{ item.amountLabel }}</span>
        <!-- <span class="unit">万元</span> -->
      </div>
      <div class="city">{{ item.provinceLabel }} • {{ item.cityLabel }}</div>
    </div>
  </div>
</template>

<script>
import { getProjectCases } from "@/api/home/home.js";
export default {
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.load();
  },
  methods: {
    load() {
      getProjectCases({ pageSize: 20 }).then(res => {
        this.dataList = res.rows;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.find-project {
  padding: 15px 20px;
  .project-item {
    min-height: 60px;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dot {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #FFC300;
    }
    .status {
      width: 10%;
      color: #666666;
      font-size: 16px;
      text-align: center;
    }
    .title {
      width: 55%;
      padding-right: 25px;
      color: #2C2C2C;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
    }
    .price-unit {
      width: 15%;
      .price {
        color: #DFA51B;
        font-size: 16px;
      }
      .unit {
        color: #2C2C2C;
        font-size: 16px;
      }
    }
    .city {
      width: 20%;
      padding-left: 10px;
      color: #666666;
      font-size: 16px;
    }
  }
}
</style>