<template>
    <div class="supply-body">
        <div class="card-title">供应商服务</div>
        <div class="content-view">
            <div class="content-center">
                <span class="title item-text">供应商服务大厅</span>
                <span class="had-supply item-text">目前已入驻供应商</span>
                <span class="count item-text">{{this.model}}<span class="unit">家</span></span>
                <div class="tag-action" >
                    <div class="request w-text " @click="showDialog()">询价</div>
                    <div class="answer w-text" @click="showDialog()">报价</div>
                </div>
            </div>  
        </div>
        <el-dialog :visible.sync="dialogVisible" top= "20vh"
        width="479px">
        <div class="card">
            <span class="phone-title">免费咨询电话</span>
            <span class="phone">13611581976</span>
        </div>
        </el-dialog>   
    </div>
</template>
<script>
 
import {getSupplyServe} from '@/api/home/home.js'
export default {
    data(){
        return{
            model:0,
            dialogVisible:false
        }
    },
  created () {
    this.load();
  },
  methods:{
    load(){
 
        getSupplyServe().then((res)=>{
            this.model = res.model
    
        })
    },
     showDialog(){
            this.dialogVisible =true
        }


  }
}
</script>

<style lang="less">
.supply-body{
    width: 100%;
    margin: 0 auto;
    .card-title{
        padding: 10px 0 30px 0;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #2C2C2C;
        line-height: 35px;       
    }
    .content-view{
        height: 395px;
        background-image: url('@/assets/img/home/bg-supply.png');
        background-size:  100% 395;
       
        background-repeat: no-repeat;
        margin: 0 auto;  
    }

    .content-center{
        display: flex;
        flex-direction: column;
        padding: 48px 0 47px 10px;

        .item-text{
            color: #412207;
        }
        .title{
            font-size: 42px;
            font-weight: 700;
            line-height: 61px;
        }
        .had-supply{
            font-size: 24px;
            font-weight: 400;
            color: rgba(65,34,7,0.9);
            line-height: 35px;
        }

        .count{
            font-size: 60px;
            font-weight: 700;
            line-height: 65px;
        }
        .unit{
           font-size: 20px;
            font-weight: 700;
            line-height: 65px; 
        }
        .tag-action{
            display: flex;
            flex-direction: row;
            align-items: center;
            .answer{
                margin: 20px 24px 0 0;
                width: 157px;
                height: 51px;
                background: linear-gradient(270deg, #FFB400 0%, #FFB400 100%);
                border-radius: 5px 5px 5px 5px;
                opacity: 1;
                border: 1px solid #DFA51B;
            }
            .request{
                margin: 20px 24px 0 0;
                width: 157px;
                height: 51px;
                background: linear-gradient(270deg, #FFA52B 0%, #FF7815 100%);
                border-radius: 5px 5px 5px 5px;
                opacity: 1;
            }
        }
        .w-text{
            text-align: center;
            line-height: 51px;
            font-size: 22px;
            color: #fff;
            font-weight: 500;
                cursor: pointer;
        }
    }
  .el-dialog{
        background: linear-gradient( #FFEBC9 0%, #FCFAF7 100%);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        
        .el-dialog__body{
            padding: 0 0 0 37px;
        }

        .phone-title{
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #663A00;
            line-height: 26px ;
           
        }
        .phone-title::before{
            position: absolute;
            content: '';
            left: -12px;
            width: 3px;
            height: 12px;
            background-color:#663A00 ;
            
        }

        .phone{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 36px;
            font-weight: 700;
            color: #F3A338;
            line-height: 44px;
        }
        .phone::after{
            padding-bottom: 15px;
            margin-left: 10px;
            content: '';
            width: 72px;
            height: 72px;
            background-image: url('@/assets/img/home/ic_phone.png');
            background-repeat: no-repeat;
         
        }
        
    }

}
</style>