<template>
<div>
  <div class="bidding">
      <div class="bidding-item" v-for="item in dataList" :key="item.code">
        <div class="content">
          <div class="title">{{ item.title }}</div>
          <div class="remark">{{ item.content }}</div>
        </div>
        <div class="recommend-btn" @click="showDialog(item.code)">
          <span>服务人员推荐</span>
          <img class="icon" src="@/assets/img/home/ic_good.png">
        </div>
      </div>
    </div>
       <el-dialog class="dialog"   width="1120px" :visible.sync="dialogTableVisible" :show-close="false">
        <div class="log-bg">
          <div class="dia-content">
           <div class="dia-top">
            <span class="top-title">服务人员推荐</span>
            <span class="top-content">多年专业精英服务专员为您服务</span>
            <img class="remove-btn" src="@/assets/img/home/ic_remove.png" @click="hiddDilalog()">
          </div>
          <div class="card-list">
            <div class="card" v-for="item in recommends" :key="item.userId">
                <img v-if="item.headPortrait" class="head" :src="item.headPortrait">
                <img v-else class="head"  src="@/assets/img/home/ic_platform_support2.png">
                <div class="card-info">
                  <span class="name">{{item.realname}}</span>
                  <span class="money">{{item.actualServiceAmount}}</span>
                  <span class="unit">元/单</span>
                </div>
                <div class="star-level">
                  <div class="count" v-for="count in 5" :key="count">
                    <img class="star" v-if="count < item.starLevel+1" src="@/assets/img/home/diamonds.png">
                    <img class="star"  v-else src="@/assets/img/home/star_no.png">
                  </div>
                </div>
                <div class="serve-quality">
                  <div class="serve-row">
                    <span class="title">服务态度：</span>
                    <el-rate
                      v-model="item.serviceAttitude"
                      disabled
                      text-color="#FFC300">
                    </el-rate>
                  </div>
                   <div class="serve-row">
                    <span class="title">服务质量：</span>
                    <el-rate
                      v-model="item.serviceQuality"
                      disabled
                      text-color="#FFC300">
                    </el-rate>
                  </div>
                   <div class="serve-row">
                    <span class="title">服务效率：</span>
                    <el-rate
                      v-model="item.serviceEfficiency"
                      disabled
                      text-color="#FFC300">
                    </el-rate>
                  </div>
                </div>
            </div>
          </div>
          <div class="log-bottom" @click="loadRecommend()">
            <img src="@/assets/img/home/reload.png">
            <span>换一换</span>
          </div>
          </div>
        </div>
      </el-dialog>
</div>
  
</template>

<script>
import { getListBidService ,getServeRecommend } from "@/api/home/home.js";
export default {
  data () {
    return {
      dataList: [],
      recommends:[],
      dialogTableVisible:false,
      currentCode:''
    }
  },
  created () {
    this.load();
  },
  methods: {
    load() {
      getListBidService().then(res => {
        this.dataList = res.rows;
      });
    },
    showDialog(code){
      this.currentCode = code;
      getServeRecommend(code).then(res =>{
        this.recommends = res.rows
      })
      this.dialogTableVisible = true
    },
    hiddDilalog(){
      this.dialogTableVisible = false
    },
    loadRecommend(){
      this.showDialog(this.currentCode);
    }
  }
};
</script>

<style lang="less" scoped>
.bidding {
  height: 100%;
  padding: 12px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  .bidding-item {
    width: 294px;
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.07);
    margin-bottom: 10px;
    .content {
      height: 166px;
      padding: 25px;
      background-image: linear-gradient(#FBEFD2, #FFFFFF);
      .title {
        padding-left: 12px;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        position: relative;
        &::before {
          content: '';
          width: 3px;
          height: 14px;
          background-color: #DFA51B;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .remark {
        margin-top: 8px;
        line-height: 22px;
        font-size: 12px;
        color: #737373;
        text-align: justify;
      }
    }
    .recommend-btn {
      height: 35px;
      background-image: linear-gradient(90deg, #DFA51B, #EFCF4F);
      color: #FFFFFF;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        width: 18px;
        height: 18px;
        margin-left: 5px;
      }
    }
  }
 
}

  .dialog{
    
     ::v-deep {
        .el-dialog {
          background: transparent;
          box-shadow: none;
        }
        .el-dialog__body,
        .el-dialog__header {
          padding: 0;
           background-color: transparent;
       }
    }
    .log-bg{
       background-color: transparent;
      background-image: url('@/assets/img/home/bg_serve_recommend.png');
      height:753px ;
    }


     .dia-content{
        padding: 100px 10px 20px;
      
        .dia-top{
          position: relative;
          display: flex;
          padding-left:55px ;
          padding-top: 80px;
          flex-direction:column;
          color: #663A00;
        .top-title{
          font-size: 28px;
          font-weight: 700;
          padding-bottom: 25px;
        }
        .top-conten{
          font-size: 20px;
          font-weight: 400;
        }
        .remove-btn{
          position: absolute;
          top: 15px;
          right: 20px;
          width: 24px;
          height: 24px;
        }
      }
        .card-list{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 54px 65px 0 64px;

          .card{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 41px 29px 41px;
            width: 315px;
            height: 337px;
            background: #FCFAF7;
            box-shadow: 0px 4px 16px 0px rgba(167,121,54,0.08);
            border-radius: 10px 10px 10px 10px;

            .head{
              width: 106px;
              height: 106px;
              margin-bottom: 21px;
              background: #FCFAF7;
              box-shadow: 0px 4px 10px 0px rgba(255,143,31,0.1);
              opacity: 1;
              border-radius: 53px;
              border: 1px solid #DFA51B;
            }

            .card-info{
              display: flex;
              align-items: baseline;
              .name{
                font-size: 18px;
                font-weight: 500;
                color: #2C2C2C;
              }
              .money{
                padding-left: 5px;
                font-size: 22px;
                font-weight: 500;
                color: #DFA51B;
              }
              .unit{
                font-size: 16px;  
                color: #666;
              }
            }
            .star-level{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding-top: 10px;
              width:200px ;

              .star{
                width: 30px;
                height: 30px;
              }
            }

            .serve-quality{
              margin-top: 29px;
              .serve-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: 13px;
                .title{
                  font-size: 18px;
                  font-weight: 400;
                  color: #2C2C2C;
                }
              }
            }
          }
         
        }
         .log-bottom{
              
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
             margin-top:37px;
               
              img{
                width: 17px;
                margin-right: 7px;
              }
              span{
              font-size: 18px;
              font-weight: 400;
              color: #663A00;
              }
            }
    }
  }  

  
</style>