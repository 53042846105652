<template>
  <div class="finance-service">
    <div class="service-group" v-for="(group, groupIdx) in dataList" :key="groupIdx">
      <div class="group-title">
        <img class="icon" :src="group.imageUrl">
        <span class="text">{{ group.title }}</span>
      </div>
      <div class="group-content">
        <div class="service-item" v-for="(item, itemIdx) in group.baseVoList" :key="itemIdx">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-remark">{{ item.content }}</div>
          <div class="item-tags">
            <span v-for="(tag, tagIdx) in item.typeFunctionList" :key="tagIdx">
              <template v-if="tagIdx"> | </template>{{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getListFinanceService } from "@/api/home/home.js";
export default {
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.load();
  },
  methods: {
    load() {
      getListFinanceService().then(res => {
        this.dataList = res.rows;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.finance-service {
  padding: 15px 20px;
  .service-group {
    .group-title {
      height: 60px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      .icon {
        width: 37px;
        height: 37px;
        margin-right: 13px;
      }
      .text {
        color: #2C2C2C;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .group-content {
      display: flex;
      flex-wrap: wrap;
      .service-item {
        width: calc(100% / 3);
        margin-bottom: 15px;
        padding-right: 25px;
        .item-title {
          color: #DFA51B;
          font-size: 18px;
          font-weight: bold;
        }
        .item-remark {
          margin-top: 15px;
          color: #2C2C2C;
          font-size: 16px;
          line-height: 1.4;
          text-align: justify;
        }
        .item-tags {
          margin-top: 10px;
          color: #9E9E9E;
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }
  }
}
</style>