/**
 * @FileDescription: 浏览器指纹识别（浏览器唯一标识）
 * @Author: chenw
 * @Date: 2022-07-12
 */
import FingerprintJS from 'fingerprintjs2';
import Cookies from 'js-cookie';
import Encode from './Encode';

export default class Fingerprint {
    constructor() {
        this.options = { monitoring: false };
        this.fingerprint = null;
    }

    getValue() {
        return new Promise((resolve, reject) => {
            if (this.fingerprint) {
                resolve(this.fingerprint);
            } else {
                let fingerprint = Encode.base64decode(Cookies.get('fingerprint') ?? '');
                if (fingerprint) {
                    this.fingerprint = fingerprint;
                    resolve(fingerprint);
                } else if (window.requestIdleCallback) {
                    requestIdleCallback(() => {
                        this.getV18().then(result => resolve(result));
                    });
                } else {
                    setTimeout(() => {
                        this.getV18().then(result => resolve(result));
                    }, 500);
                }
            }
        });
    }

    getV18() {
        return new Promise((resolve, reject) => {
            //必须在v2.0语法提供options参数
            FingerprintJS.getV18(this.options, (result, components) => {
                this.fingerprint = result;
                Cookies.set('fingerprint', Encode.base64encode(result), { expires: 30 });
                //结果是哈希指纹
                resolve(result);
            })
        });
    }
}