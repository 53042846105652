<template>
  <div class="main-tabs content-center">
    <div class="module-title">同命运，共富裕</div>
    <div class="module-remark">国内信息化基础建设领域唯一的云企业平台</div>
    <div class="tab" :style="{ '--tab-item-w': `calc(100% / ${tabs.length})` }">
      <div class="tab-nav">
        <div class="tab-nav-item" :class="{ 'is-active': index == current }" v-for="(tab, index) in tabs" :key="index" @click="currentChange(index)">
          <img class="tab-nav-icon" :src="tab.src" :alt="tab.title">
          <div class="tab-nav-title">{{ tab.title }}</div>
          <div class="tab-nav-remark">{{ tab.remark }}</div>
        </div>
      </div>
      <div class="tab-bar-box">
        <div class="tab-bar" :style="`left: calc(${current} * var(--tab-item-w))`"></div>
      </div>
      <div class="tab-content">
        <div class="tabpanel" :class="{ 'is-active': index == current }" v-for="(tab, index) in tabs" :key="index">
          <component :is="tab.id"></component>
        </div>
      </div>
      <!-- 平台保障 -->
      <PlatformSupport></PlatformSupport>
    </div>
  </div>
</template>

<script>
import PlatformSupport from './components/PlatformSupport.vue';
import TabService from './components/TabService.vue';
import TabCompany from './components/TabCompany/index.vue';
import TabSupplier from './components/TabSupplier.vue';
export default {
  components: { PlatformSupport, TabService, TabCompany, TabSupplier },
  data() {
    return {
      current: 0,
      tabs: [
        { id: 'TabService', title: '热门服务', remark: '在线快速查找你需要的优质服务', src: require('@/assets/img/home/ic_main_tab_service.png') },
        { id: 'TabCompany', title: '我是云企业', remark: '在线快速查找你需要的优质服务', src: require('@/assets/img/home/ic_main_tab_company.png') },
        { id: 'TabSupplier', title: '我是供应商', remark: '在线快速查找你需要的优质服务', src: require('@/assets/img/home/ic_main_tab_supplier.png') }
      ]
    };
  },
  methods: {
    currentChange(index) {
      this.current = index;
    },
  },
};
</script>

<style lang="less" scoped>
.main-tabs {
  margin-top: 30px;
  .tab {
    .tab-nav {
      margin-top: 34px;
      margin-bottom: 40px;
      display: flex;
      .tab-nav-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &:hover .tab-nav-icon {
          filter: grayscale(50%);
        }
        &.is-active .tab-nav-icon {
          filter: grayscale(0%);
        }
        .tab-nav-icon {
          width: 134px;
          height: 134px;
          filter: grayscale(100%);
          transition: filter 0.3s ease;
        }
        .tab-nav-title {
          margin-top: 8px;
          color: #2C2C2C;
          font-size: 24px;
          font-weight: bold;
        }
        .tab-nav-remark {
          margin-top: 18px;
          color: #9E9E9E;
          font-size: 16px;
        }
      }
    }
    .tab-bar-box {
      height: 7px;
      border-radius: 3.5px;
      background-color: #F7F7F6;
      position: relative;
      .tab-bar {
        width: var(--tab-item-w);
        height: 7px;
        border-radius: 3.5px;
        background-color: #DFA51B;
        position: absolute;
        top: 0;
        transition: left 0.3s ease;
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid #DFA51B;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 80%);
        }
      }
    }
    .tab-content {
      margin: 28px 0 20px;
      .tabpanel {
        display: none;
        &.is-active {
          display: block;
        }
      }
    }
  }
}
</style>