/**
 * @FileDescription: 系统-验证
 * @Author: fanqixin
 * @Date: 2021-6-23 10:37:49
 * @LastEditors: fanqixin
 * @LastEditTime: 2021-6-23 10:37:49
 */
import axios from "../base/axios.js";

const CaptchaApi = {
	/**
	 * @description: 基础地址
	 */
	baseUrl: function() {
		return '/longstron/system/captcha';
	},
	/**
	 * @description: 获取滑块滑块验证
	 */
	getSlidingCaptcha: function(data) {
		return axios.post(this.baseUrl() + '/getSlidingCaptcha', data);
	},
	/**
	 * @description: 验证滑块验证码
	 */
	checkSlidingCaptcha: function(data) {
		return axios.post(this.baseUrl() + '/checkSlidingCaptcha', data);
	},
	/**
	 * @description: 获取手机验证码
	 */
	getPhoneCaptcha: function(data) {
		return axios.post(this.baseUrl() + '/getPhoneCaptcha', data);
	}
};

export default CaptchaApi;
