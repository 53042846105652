<template>
    <div class="bg">
        <div :style="{backgroundImage: 'url(' + item.imageUrl + ')' ,   }" class="top">  
            <span class="pr-note">{{item.title}}</span>
        </div>
        <div class="pr-content pr-text">{{item.content}}</div>
         <div class="bottom">
            <span class="adrress">全国</span>
            <span class="money">￥<span class="money-text">{{item.priceAmount}}</span></span>
            
        </div>
        <div class="cover"></div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {};
            }

        }
    },
 
}
</script>

<style lang="less" scoped>
    .bg{
        position: relative;
       width: 287px;
       height: 299px;
       background-color: white;
       box-shadow: 0px 10px 19px 0px rgba(0,0,0,0.03);
       border-bottom:10px solid rgba(0,0,0,0.01) ;
       
    .top{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: 20px;
        width: 287px;
        height: 138px;
        padding: 25px 20px 0px 18px;
        background-size:cover ;
        .pr-note{
            left: 22px;
            position: absolute;
            bottom: 24px;
            font-size: 12px;
            font-weight: 400;
            color: white;
        }
    }
        .pr-content{
            padding:0 18px;
            font-size: 14px;
            font-weight: 400;
            color: #2C2C2C;
            line-height: 25px;
         }

       .bottom{
        position: absolute;
        bottom: 22px;
        left: 20px;
        display: flex;
        width: 257px;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        .money{
            padding-right: 10px;
            font-size: 15px;
            font-weight: 600;
            color: rgb(225, 173, 100);
            line-height: 26px;

            .money-text{
                font-size: 16px;
                color: rgb(225, 173, 100);
                font-weight: 700;
            }
        }
        .adrress{
            padding-left: 17px;
            position: relative;
            font-size: 14px;
            color: #9E9E9E;
        }
        .adrress::before{
            position: absolute;
            left: 0;
            content: '';
            width: 16px;
            height: 16px;
            background: url('@/assets/img/home/serve_location.png');
        }
       }

    }
        .cover {
        position: absolute;
        top: 0;
       width: 100%;
       height: 100%;
    }
    .cover:hover{
        background-color: rgba(0,0,0,0.05);
    }
</style> 