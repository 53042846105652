<template>
  <el-carousel height="433px" :interval="5000" trigger="click">
    <el-carousel-item v-for="(carousel, index) in carouselList" :key="index">
      <div class="item" :style="`background: url(${carousel.src});background-size: cover;background-position: center;`">
        <div class="content-center"></div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data () {
    return {
      carouselList: [
        { src: require('@/assets/img/home/bg_carousel1.png') },
        { src: require('@/assets/img/home/bg_carousel2.png') },
        { src: require('@/assets/img/home/bg_carousel3.png') }
      ]
    }
  }
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__indicator--horizontal {
  padding: 19px 5px;

  .el-carousel__button {
    width: 12px;
    height: 5px;
    border-radius: 2.5px;
    background: rgba(223, 165, 27, 0.3);
  }

  &.is-active .el-carousel__button {
    width: 38px;
    background: rgba(223, 165, 27, 1);
  }
}

.item {
  height: 100%;
}
</style>