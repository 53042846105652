import axios from "axios";
import Message from '@/utils/message.js';
// import Loading from '@/utils/loading.js';
import store from '@/store';
// import router from '@/router';
import localCache from '@/localCache';

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000 // 30s
});

// 请求列表(防重复提交)
let requestList = [];
let cancelToken = axios.CancelToken;

service.interceptors.request.use(config => {
  //防止重复提交（如果本次是重复操作，则取消，否则将该操作标记到requestList中）
  config.cancelToken = new cancelToken((cancel) => {
    let requestFlag = JSON.stringify(config.url) + JSON.stringify(config.data) + '&' + config.method;
    if (requestList.includes(requestFlag)) {//请求标记已经存在，则取消本次请求，否则在请求列表中加入请求标记
      requestList.push(requestFlag);
    } else {
      requestList.push(requestFlag);
    }
  });

  // loading
  // if (requestList.length) Loading.open();

  // 获取token
  const token = localCache.get("USER_TOKEN");
  if (token) config.headers['Authorization'] = token;

  return config;
}, error => {
  return Promise.reject(error);
});

service.interceptors.response.use(response => {
  //请求返回后，将请求标记从requestList中移除
  let requestFlag = JSON.stringify(response.config.url) + JSON.stringify(response.config.data) + '&' + response.config.method;
  requestList.splice(requestList.findIndex(item => item === requestFlag), 1);

  // loading
  //  if (!requestList.length) Loading.close();

  // 访问服务器失败
  if (response.status < 200 || response.status > 206) {
    Message.error(response.status + " ：访问服务器错误");
    return Promise.resolve('error');
  }

  // 成功返回数据
  if (response.data?.code != '00000') {
    Message.error(response.data.code + " ：" + response.data.message);
    if (['A0301', 'A0230', 'A0300', 'A0212', 'A0231'].includes(response.data.code)) {
     
      localCache.remove("USER_TOKEN");
      // router.push("/login");
    }
    return Promise.resolve(response);
  }
  return Promise.resolve(response.data);
}, error => {
  // 置空请求列表
  requestList.length = 0;
  // 错误状态提示
  if (error?.response) {
    switch (error.response.status) {
      case 400: error.message = '错误请求'; break;
      case 401: error.message = '未授权，请重新登录'; break;
      case 403: error.message = '拒绝访问'; break;
      case 404: error.message = '请求错误,未找到该资源'; break;
      case 405: error.message = '请求方法未允许'; break;
      case 408: error.message = '请求超时'; break;
      case 500: error.message = '服务器端出错'; break;
      case 501: error.message = '网络未实现'; break;
      case 502: error.message = '网络错误'; break;
      case 503: error.message = '服务不可用'; break;
      case 504: error.message = '网络超时'; break;
      case 505: error.message = 'http版本不支持该请求'; break;
      default: error.message = `连接错误${error.response.status}`
    }
    Message.error(error.response.status + " ：" + error.message);
  } else {
    error.message = "连接到服务器失败";
    Message.error(error.message);
  }
  return Promise.reject(error.message);
});

export default service;