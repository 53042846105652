<template>
<div>
    <div class="find-scheme">
    <div class="scheme-item" v-for="item in dataList" :key="item.programmeList.id" >
      <el-image class="image" :src="item.programmeList.headImgUrl"></el-image>
      <div class="content" >
        <p class="title">{{ item.programmeList.name }}</p>
        <p class="date" @click="showFile(item.fileList)">查看文件</p>
      </div>
    </div>
  </div>
  <login-dialog  ref="loginRef"></login-dialog>
</div>
</template>

<script>
import Util from '@/utils/index.js';
import localCache from '@/localCache';
import LoginDialog from '../../../../LoginDialog/index.vue';
import { getProgramme } from "@/api/home/home.js";
 
export default {
    components:{
      LoginDialog,
  },
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.load();
  },
  methods: {
    load() {
      getProgramme({ pageSize: 10 ,programmeType:["DESIGN"]}).then(res => {
        this.dataList = res.rows;
      });
    },
    showFile(array){
       this.token = localCache.get("USER_TOKEN");
        if (this.token) {
            if (array.length >0) {
          let file = array[0];
          let type = Util.getFileType(file.fileName);
          if (type === 'pdf' || type === 'txt') {
              window.open(file.filePath);
            } else  if (type === 'word' || type === 'excel' || type === 'ppt') {
              window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.filePath)}`);
            } else{
              console.log(type)
            }
      }
        }else{
           this.$confirm('未登录不可预览文件, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.showLogin()
        }).catch(() => {
            
        });
        }
    },
    showLogin(){
      this.$refs.loginRef.dialogVisible = true; 
    }

  }
};
</script>

<style lang="less" scoped>
.find-scheme {
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  .scheme-item {
    display: flex;
    .image {
      flex-shrink: 0;
      width: 142px;
      height: 83px;
      box-shadow: 0px 4px 10px 0px rgba(235,191,69,0.5);
    }
    .content {
      flex: 1;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        color: #2C2C2C;
        font-size: 15px;
        font-weight: bold;
        line-height: 24px;
        text-align: justify;
      }
      .date {
        padding-left: 5px;
        font-size: 14px;
        color: #9E9E9E;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }
}
</style>