import CaptchaApi from '@/api/system/captchaApi.js';

const PI = Math.PI;
var offset = 0; // 滑块允许偏移量
var dateTimes = 0;
var key = null;
var ipAddress = null;
var hint_message = "向右滑动完成验证";

const slider_l = 40; // 滑块边长
const slider_r = 9; // 滑块中突出圆半径
const slider_L = slider_l + slider_r * 2 + 3; // 滑块实际边长
const canvas_width = 310; // canvas宽度
const canvas_height = 155; // canvas高度

// 创建一个画布
function createImageCanvas(width, height) {
	const imageCanvas = createElement('canvas', 'imageCanvas');
	imageCanvas.width = width;
	imageCanvas.height = height;
	imageCanvas.style.borderRadius = '3px';
	return imageCanvas;
}

// 创建一个图片
function createImg(onload, imageIndex) {
	const img = createElement('img');
	img.crossOrigin = "anonymous";
	img.onload = onload;
	img.onerror = () => {
		img.src = getRandomImg();
	};
	img.src = getLocalRandomImg(imageIndex);
	return img;
}

// 创建一个html元素
function createElement(tagName, className, id) {
	const elment = document.createElement(tagName);
	if (className) elment.className = className;
	if (id) elment.id = id;
	return elment;
}

// 为html元素添加一个类
function addClass(tag, className) {
	tag.classList.add(className);
}

// 为html元素删除一个类
function removeClass(tag, className) {
	tag.classList.remove(className);
}

// 随机获取一个图片的路径
function getRandomImg() {
	return 'https://picsum.photos/300/150/?image=' + getRandomNumberByRange(0, 1000);
}

function getLocalRandomImg(imageIndex) {
	return '/slidingCaptcha/images/' + imageIndex + '.jpg';
}

// 获取一个随机数
function getRandomNumberByRange(start, end) {
	return Math.round(Math.random() * (end - start) + start);
}

function draw(ctx, x, y, operation) {
	ctx.beginPath();
	ctx.moveTo(x, y);
	ctx.arc(x + slider_l / 2, y - slider_r + 2, slider_r, 0.72 * PI, 2.26 * PI);
	ctx.lineTo(x + slider_l, y);
	ctx.arc(x + slider_l + slider_r - 2, y + slider_l / 2, slider_r, 1.21 * PI, 2.78 * PI);
	ctx.lineTo(x + slider_l, y + slider_l);
	ctx.lineTo(x, y + slider_l);
	ctx.arc(x + slider_r - 2, y + slider_l / 2, slider_r + 0.4, 2.76 * PI, 1.24 * PI, true);
	ctx.lineTo(x, y);
	ctx.lineWidth = 2;
	ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
	ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)';
	ctx.stroke();
	ctx[operation]();
	ctx.globalCompositeOperation = 'destination-over';
}

function sum(x, y) {
	return x + y;
}

function square(x) {
	return x * x;
}

class slidingCaptcha {
	constructor({
		el,
		type,
		onSuccess,
		onFail,
		onRefresh,
		onClose
	}) {
		this.el = el;
		this.type = type; // popup：弹出式，trigger：触发式，embed：内嵌式
		this.onSuccess = onSuccess;
		this.onFail = onFail;
		this.onRefresh = onRefresh;
		this.onClose = onClose
	}

	init(initType) {
		this.trail = [];
		this.initDOM();
		this.initParam(initType);
	}

	initParam(initType) {
		let param = {
			"canvas_width": canvas_width,
			"canvas_height": canvas_height,
			"slider_l": slider_l,
			"slider_r": slider_r,
			"dateTimes": dateTimes,
			"type": initType
		}
		CaptchaApi.getSlidingCaptcha(param).then(res => {
			if (res && res.code === '00000') {
				let data = res.model;

				offset = data["offset"]; // 滑块允许偏移量
				dateTimes = data["dateTimes"];
				ipAddress = data["ipAddress"];
				key = data["key"];

				this.initImg(data["x_coordinate"], data["y_coordinate"], data["imageIndex"]);
				if (initType == "init") {
					this.bindEvents();
				}
			}
		})
	}

	initDOM() {
		// 弹出式
		if (this.type == 'popup') {
			this.initPopup();
			// 触发式
		} else if (this.type == 'trigger') {
			this.initTrigger();
			// 内嵌式
		} else if (this.type == 'embed') {
			this.initEmbed();
		}
	}

	// 弹出式
	initPopup() {
		const el = this.el;
		el.innerHTML = "";
		el.className = 'captcha-popup';
		el.style.display = "block";

		// 蒙层模板
		const captchaMask = createElement('div', 'captcha-mask');
		const captchaWrap = createElement('div', 'captcha-wrap');
		const captchaSubwrap = createElement('div', 'captcha-subwrap');
		const captchaModal = createElement('div', 'captcha-modal');

		// 标题
		const captchaModalTitle = createElement('div', 'captcha-modal-title');
		const captchaModalTitleText = createElement('span', 'captcha-modal-title-text');
		const captchaModalClose = createElement('span', 'captcha-modal-close', 'captchaModalClose');
		const captchaIconClose = createElement('span', 'captcha-icon-close');
		captchaModalTitleText.innerHTML = "请完成安全验证";
		captchaModalClose.appendChild(captchaIconClose);
		captchaModalTitle.appendChild(captchaModalTitleText);
		captchaModalTitle.appendChild(captchaModalClose);
		captchaModal.appendChild(captchaModalTitle);

		// 验证码内容
		const captchaModalBody = createElement('div', 'captcha-modal-body');
		const slidingCaptcha = createElement('div', 'slidingCaptcha');
		slidingCaptcha.style.position = slidingCaptcha.style.position || 'relative';
		slidingCaptcha.style.width = canvas_width + "px";
		slidingCaptcha.style.margin = "auto";

		const imageCanvas = createImageCanvas(canvas_width, canvas_height); // 画布
		const block = imageCanvas.cloneNode(true); // 滑块
		const refreshIcon = createElement('div', 'refreshIcon');
		const canvasContainer = createElement('div', 'canvasContainer');

		const sliderContainer = createElement('div', 'sliderContainer');
		const sliderMask = createElement('div', 'sliderMask');
		const slider = createElement('div', 'slider');
		const sliderIcon = createElement('span', 'sliderIcon');
		const text = createElement('span', 'sliderText');

		block.className = 'block';
		text.innerHTML = hint_message;

		canvasContainer.appendChild(imageCanvas);
		canvasContainer.appendChild(refreshIcon);
		canvasContainer.appendChild(block);
		slidingCaptcha.appendChild(canvasContainer);

		slider.appendChild(sliderIcon);
		sliderMask.appendChild(slider);
		sliderContainer.appendChild(sliderMask);
		sliderContainer.appendChild(text);
		slidingCaptcha.appendChild(sliderContainer);

		captchaModalBody.appendChild(slidingCaptcha);
		captchaModal.appendChild(captchaModalBody);
		captchaSubwrap.appendChild(captchaModal);
		captchaWrap.appendChild(captchaSubwrap);

		// 放入页面对象
		el.appendChild(captchaMask);
		el.appendChild(captchaWrap);

		captchaModalClose.addEventListener('click', () => {
			if (captchaModal == null) {
				return;
			}
			captchaModal.className = 'captcha-modal popup-ease-leave-active';
			captchaMask.style.opacity = 0;
			setTimeout(() => {
				el.style.display = "none";
				captchaModal.className = "captcha-modal";
			}, 500);
			typeof this.onClose === 'function' && this.onClose();
		});

		captchaMask.addEventListener('click', () => {
			if (captchaModal == null) {
				return;
			}
			captchaModal.className = 'captcha-modal popup-ease-leave-active';
			captchaMask.style.opacity = 0;
			setTimeout(() => {
				el.style.display = "none";
				captchaModal.className = "captcha-modal";
			}, 500);
			typeof this.onClose === 'function' && this.onClose();
		});

		Object.assign(this, {
			imageCanvas,
			block,
			canvasContainer,
			sliderContainer,
			refreshIcon,
			slider,
			sliderMask,
			captchaModal,
			captchaMask,
			captchaModalClose,
			sliderIcon,
			text,
			canvasCtx: imageCanvas.getContext('2d'),
			blockCtx: block.getContext('2d')
		});
	}

	// 触发式
	initTrigger() {

		const el = this.el;
		el.style.position = el.style.position || 'relative';
		el.style.width = canvas_width + "px";
		el.className = 'captcha-trigger';
		el.innerHTML = "";

		const imageCanvas = createImageCanvas(canvas_width, canvas_height); // 画布
		const block = imageCanvas.cloneNode(true); // 滑块
		const refreshIcon = createElement('div', 'refreshIcon');
		const canvasContainer = createElement('div', 'canvasContainer captcha-trigger-canvas-container');

		const sliderContainer = createElement('div', 'sliderContainer');
		const sliderMask = createElement('div', 'sliderMask');
		const slider = createElement('div', 'slider');
		const sliderIcon = createElement('span', 'sliderIcon');
		const text = createElement('span', 'sliderText');

		block.className = 'block';
		text.innerHTML = hint_message;

		canvasContainer.appendChild(imageCanvas);
		canvasContainer.appendChild(refreshIcon);
		canvasContainer.appendChild(block);
		canvasContainer.style.display = "none";

		el.appendChild(canvasContainer);

		slider.appendChild(sliderIcon);
		sliderMask.appendChild(slider);
		sliderContainer.appendChild(sliderMask);
		sliderContainer.appendChild(text);
		el.appendChild(sliderContainer);

		slider.addEventListener('mouseenter', function(ev) {
			var oEvent = ev || event;
			var oFrom = oEvent.fromElement || oEvent.relatedTarget;
			if (this.contains(oFrom)) {
				return false;
			}
			if (canvasContainer.style.display == "none") {
				canvasContainer.style.display = "block";
			}
		});
		slider.addEventListener('mouseleave', function(ev) {
			var oEvent = ev || event;
			var oTo = oEvent.toElement || oEvent.relatedTarget;
			if (this.contains(oTo)) {
				return false;
			}
			canvasContainer.className =
				'canvasContainer captcha-trigger-canvas-container captcha-trigger-fade-out';
			setTimeout(() => {
				if (canvasContainer.style.display == "block") {
					canvasContainer.style.display = "none";
				}
				canvasContainer.className = 'canvasContainer captcha-trigger-canvas-container';
			}, 500);
		});

		Object.assign(this, {
			imageCanvas,
			block,
			canvasContainer,
			sliderContainer,
			refreshIcon,
			slider,
			sliderMask,
			sliderIcon,
			text,
			canvasCtx: imageCanvas.getContext('2d'),
			blockCtx: block.getContext('2d')
		});
	}

	// 内嵌式
	initEmbed() {
		const el = this.el;
		el.style.position = el.style.position || 'relative';
		el.style.width = canvas_width + "px";
		el.style.margin = "15px auto";
		el.style.display = "block";
		el.innerHTML = "";

		const imageCanvas = createImageCanvas(canvas_width, canvas_height); // 画布
		const block = imageCanvas.cloneNode(true); // 滑块
		const refreshIcon = createElement('div', 'refreshIcon');
		const canvasContainer = createElement('div', 'canvasContainer');

		const sliderContainer = createElement('div', 'sliderContainer');
		const sliderMask = createElement('div', 'sliderMask');
		const slider = createElement('div', 'slider');
		const sliderIcon = createElement('span', 'sliderIcon');
		const text = createElement('span', 'sliderText');

		block.className = 'block';
		text.innerHTML = hint_message;

		canvasContainer.appendChild(imageCanvas);
		canvasContainer.appendChild(refreshIcon);
		canvasContainer.appendChild(block);
		el.appendChild(canvasContainer);

		slider.appendChild(sliderIcon);
		sliderMask.appendChild(slider);
		sliderContainer.appendChild(sliderMask);
		sliderContainer.appendChild(text);
		el.appendChild(sliderContainer);

		Object.assign(this, {
			imageCanvas,
			block,
			canvasContainer,
			sliderContainer,
			refreshIcon,
			slider,
			sliderMask,
			sliderIcon,
			text,
			canvasCtx: imageCanvas.getContext('2d'),
			blockCtx: block.getContext('2d')
		});
	}

	closeCaptcha() {
		if (this.captchaModal == null) {
			return;
		}
		this.captchaModal.className = 'captcha-modal popup-ease-leave-active';
		this.captchaMask.style.opacity = 0;
		setTimeout(() => {
			this.el.style.display = "none";
			this.captchaModal.className = "captcha-modal";
		}, 500);
		typeof this.onClose === 'function' && this.onClose();
	}

	initImg(x_coordinate, y_coordinate, imageIndex) {
		const img = createImg(() => {
			this.clean();
			this.draw(x_coordinate, y_coordinate);
			this.canvasCtx.drawImage(img, 0, 0, canvas_width, canvas_height);
			this.blockCtx.drawImage(img, 0, 0, canvas_width, canvas_height);
			const y = this.y - slider_r * 2 - 1;
			const ImageData = this.blockCtx.getImageData(this.x - 3, y, slider_L, slider_L);
			this.block.width = slider_L;
			this.blockCtx.putImageData(ImageData, 0, y);
		}, imageIndex);
		this.img = img;
	}

	draw(x_coordinate, y_coordinate) {
		// 随机创建滑块的位置
		this.x = x_coordinate;
		this.y = y_coordinate;
		draw(this.canvasCtx, this.x, this.y, 'fill');
		draw(this.blockCtx, this.x, this.y, 'clip');
	}

	clean() {
		this.canvasCtx.clearRect(0, 0, canvas_width, canvas_height);
		this.blockCtx.clearRect(0, 0, canvas_width, canvas_height);
		this.block.width = canvas_width;
	}

	bindEvents() {
		this.el.onselectstart = () => false;
		this.refreshIcon.onclick = () => {
			this.reset("向右滑动完成验证");
			typeof this.onRefresh === 'function' && this.onRefresh();
		};

		let originX,
			originY,
			isMouseDown = false;

		// 鼠标按下时，移动开始
		const handleDragStart = function(e) {
			originX = e.touches == null ? e.clientX : e.touches[0].clientX;
			originY = e.touches == null ? e.clientY : e.touches[0].clientY;
			isMouseDown = true;
		};

		// 鼠标左右移动时
		const handleDragMove = (e) => {
			if (!isMouseDown) {
				return false;
			}
			const eventX = e.touches == null ? e.clientX : e.touches[0].clientX;
			const eventY = e.touches == null ? e.clientY : e.touches[0].clientY;
			const moveX = eventX - originX;
			const moveY = eventY - originY;
			if (moveX < 0 || moveX + 38 >= canvas_width) {
				return false;
			}
			this.slider.style.left = moveX + 'px';
			const blockLeft = (canvas_width - 40 - 20) / (canvas_width - 40) * moveX;
			this.block.style.left = blockLeft + 'px';

			addClass(this.sliderContainer, 'sliderContainer_active');
			this.sliderMask.style.width = (moveX + 40) + 'px';
			this.trail.push(moveY);
		}

		// 鼠标松开，移动结束
		const handleDragEnd = (e) => {
			if (!isMouseDown) {
				return false;
			}
			isMouseDown = false;
			const eventX = e.changedTouches == null ? e.clientX : e.changedTouches[0].clientX;
			if (eventX == originX) {
				return false;
			}
			removeClass(this.sliderContainer, 'sliderContainer_active');
			const left = parseInt(this.block.style.left) + 3;

			// 访问后台校验
			let param = {
				"y_coordinate": this.trail,
				"x_coordinate": left,
				"dateTimes": dateTimes,
				"ipAddress": ipAddress,
				"key": key
			}

			CaptchaApi.checkSlidingCaptcha(param).then(res => {
				if (res && res.code === '00000' && res.model.successful === true) {
					addClass(this.sliderContainer, 'sliderContainer_success');
					typeof this.onSuccess === 'function' && this.onSuccess(key);
					this.closeCaptcha();
				} else {
					addClass(this.sliderContainer, 'sliderContainer_fail');
					typeof this.onFail === 'function' && this.onFail();
					this.reset("请再试一次");
				}
			}).catch(error => {
				console.log(error);
				this.reset("验证失败，请再试一次");
			});
		}

		this.slider.addEventListener('mousedown', handleDragStart);
		this.slider.addEventListener('touchstart', handleDragStart);
		document.addEventListener('mousemove', handleDragMove);
		document.addEventListener('touchmove', handleDragMove);
		document.addEventListener('mouseup', handleDragEnd);
		document.addEventListener('touchend', handleDragEnd);
	}

	// 简单验证
	verify() {
		const arr = this.trail; // 拖动时y轴的移动距离
		const average = arr.reduce(sum) / arr.length;
		const deviations = arr.map(x => x - average);
		const stddev = Math.sqrt(deviations.map(square).reduce(sum) / arr.length);
		const left = parseInt(this.block.style.left) + 3;

		return {
			// 偏移量，位置验证
			spliced: Math.abs(left - this.x) <= offset,
			// 简单验证下拖动轨迹，为零时表示Y轴上下没有波动，可能非人为操作
			verified: stddev !== 0,
		};
	}

	// 重置
	reset(hintMessage) {
		setTimeout(() => {
			this.sliderContainer.className = 'sliderContainer';
			this.slider.style.left = 0;
			this.block.style.left = 0;
			this.sliderMask.style.width = 0;
			this.text.innerHTML = hintMessage;
			this.trail = [];
			this.clean();
			this.initParam("reset");
		}, 500);
	}
}

const captcha = {
	init: function(opts) {
		return new slidingCaptcha(opts).init("init");
	},
}
export default captcha;
