/**
 * @FileDescription: ElementUI Message重新封装
 * @Author: fanqixin
 * @Date: 2021-7-2 20:43:10
 * @LastEditors: fanqixin
 * @LastEditTime: 2021-7-2 20:43:10
 */
import {
	Message
} from 'element-ui';

let messageInstance = null; // 消息实例

// 限制消息弹出数量上限
const vMessage = (options) => {
	if (messageInstance) {
		messageInstance.close()
	}
	messageInstance = Message(options)
};


// 默认参数
['error', 'success', 'info', 'warning'].forEach(type => {
	vMessage[type] = options => {
		if (typeof options === 'string') {
			options = {
				message: options,
				showClose: true,
				customClass: 'global-message'
			}
		}
		options.type = type;
		return vMessage(options);
	}
});

export default vMessage;
