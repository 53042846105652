<template>
  <div class="project-business">
    <div class="flow-chart">
      <img class="image" src="@/assets/img/home/project_manage_flow_chart.png">
    </div>
    <div class="cooperation-model">
      <div class="model-title">项目合作模式：</div>
      <div class="model-item" v-for="(item, index) in models" :key="index">
        <div class="title">
          <img class="icon" :src="item.src">
          <span class="text">{{ item.title }}</span>
        </div>
        <div class="remark">{{ item.remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      models: [
        {
          title: '自营模式',
          src: require('@/assets/img/home/ic_project_model1.png'),
          remark: '利用自有团队对外承接项目，在平台上进行项目的实施管理，自主完成项目。'
        },
        {
          title: '委托模式',
          src: require('@/assets/img/home/ic_project_model2.png'),
          remark: '对外承接项目，中标后，委托平台进行项目的现场管理、技术支撑和售后服务。'
        },
        {
          title: '合伙模式',
          src: require('@/assets/img/home/ic_project_model3.png'),
          remark: '依靠平台开放的管理工具和资源，对优质的潜在项目，合伙开发，监督经营，利润共享。'
        }
      ]
    }
  }
};
</script>

<style lang="less" scoped>
.project-business {
  height: 100%;
  display: flex;
  flex-direction: column;
  .flow-chart {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 877px;
      height: 326px;
    }
  }
  .cooperation-model {
    height: 90px;
    padding: 0 20px;
    border: 1px dashed #DFA51B;
    background: linear-gradient(90deg, rgba(234, 188, 96, 0.05), rgba(224, 167, 30, 0.05));
    display: flex;
    justify-content: space-between;
    align-items: center;
    .model-title {
      color: #DFA51B;
      font-size: 16px;
      font-weight: bold;
    }
    .model-item {
      width: 242px;
      .title {
        display: flex;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          margin-right: 5px;
        }
        .text {
          color: #DFA51B;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .remark {
        margin-top: 6px;
        color: #2C2C2C;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

</style>