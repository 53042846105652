<template>
    <div class="footer">
        <div class="footer-body content-center">
            <div class="footer-main">
                <div class="content-left">
                    <dl class="left-dl">
                        <dt> <img src="@/assets/img/home/logo.png" alt=""> </dt>
                        <dd>CopyRight©2014-2022 Inc.All Right Reserved</dd>
                        <dd>隆创信息有限公司 </dd>
                        <dd>Tel: 025-66678988 / 400 009 2860</dd>
                    </dl>
                </div>
                <div class="content-right">
                    <dl class="right-dl">
                        <dt> 隆创信息 </dt>
                        <dd @click="goAbout()">关于我们</dd>
                        <dd @mouseleave="showAppcode()" @mouseover="showAppcode()">APP下载</dd>
                    </dl>
                    <div v-if="isShowCode" class="show_code"><img src="@/assets/img/home/app_code.png"></div>
                </div>  
            </div>

            <div class="message-bar">
                <div class="message-row">
                    <el-link href="http://beian.miit.gov.cn" target="_blank" type="info" :underline="false">
                        <span class="message-link">苏ICP备14056357号-3</span>
                    </el-link>
                    <span class="message-text"> &nbsp;| Copyright © 2014-{{ new Date().getFullYear() }} Inc.All Rights Reserved&nbsp;</span>
                    <el-link href="http://www.longstron.com" target="_blank" type="info" :underline="false">
                        <span class="message-link">隆创信息有限公司</span>
                    </el-link>
                    <span class="message-text">&nbsp;版权所有</span>
                </div>
                <div class="message-row">公司地址：南京市雨花台区宁双路19号云密城F栋12楼</div>
                <div class="message-row">
                    <el-link
                        href="https://beian.mps.gov.cn/#/query/webSearch?code=32011402011690"
                        target="_blank"
                        type="info"
                        :underline="false"
                    >
                        <div class="message-link">
                            <img src="@/assets/img/home/ic_beian.png" alt="">
                            <span>苏公网安备32011402011690</span>
                        </div>
                    </el-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 export default{
    data(){
        return{
            isShowCode:false
        }
    },
    methods:{
        goAbout(){
            if ('aboutUs' == this.$route.name) {
                return
            }
              document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.$router.push('/aboutUs' );
        },
        showAppcode(){
            this.isShowCode = !this.isShowCode
        }
    }
 }
</script>

<style scoped lang='less'>
.footer{
    background-color:#2c2c2c;

    .footer-body{
        padding: 16px 0;

        .footer-main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .left-dl dt{
                display: flex;
                align-items: center;
                padding-bottom: 15px;
                font-size:20px ;
                font-weight: 500;
                color:white;
                img{
                   
                    width: 60px;
                }
            }
            .left-dl dd{
                padding-bottom: 9px;
                font-size:14px ;
                font-weight: 400;
                color:white;
                animation: all 0.5s;
            }
            /* .left-dl dd:hover{
                font-size:15px ;
                color:#E5B52F; 
            } */
            .right-dl dt{
                padding-bottom: 20px;
                font-size:16px ;
                font-weight: 400;
                color:white; 

            }
            .right-dl dd{
                padding-bottom: 9px;
                font-size:14px ;
                font-weight: 400;
                color:white; 
                animation: all 0.5s ;
                cursor: pointer;
            }
            .right-dl dd:hover{
                // font-size:15px ;
                color:#E5B52F; 
            }
            .content-right,.down-app{
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .down-app::before{
                    content: '';
                    margin: 0 36px 0 46px;
                    width: 1px;
                    height:105px ;
                    background-color: white;
                }
                
                .img{
                    margin: 10px;
                    width: 62px;
                    height: 62px;
                    background-color: white;
                }
                .app-title{
                    font-size: 12px;
                    color: white;
                    text-align: center;
                }
            }
        }

        .message-bar {
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;

            .message-row {
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .message-link {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;

                &:hover {
                    color: #E5B52F;
                }
            }
        }
    }
    .show_code{
        position: absolute;
        top: 10px;
        right: 70px;
        width: 133px;
        height: 133px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        border-radius: 5px;
        
        img{
            margin:5px;
            width: 123px;
            height: 123px;
                
        }
    }
}
</style>