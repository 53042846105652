<template>
  <div class="tab-company">
    <div class="tabs-nav">
      <div class="nav-item" :class="{ 'is-active': index == current }" v-for="(tab, index) in tabs" :key="index" @click="currentChange(index)">
        <p class="title">{{ tab.title }}</p>
        <p class="remark">{{ tab.remark }}</p>
      </div>
    </div>
    <div class="tabs-content" :style="{ height: `${tabs.length * 90}px` }">
      <div class="tabpanel" :class="{ 'is-active': index == current }" v-for="(tab, index) in tabs" :key="index">
        <component :is="tab.id"></component>
      </div>
    </div>
  </div>
</template>

<script>
import FindProject from './components/FindProject.vue';
import FindScheme from './components/FindScheme.vue';
import Bidding from './components/Bidding.vue';
import ProjectBusiness from './components/ProjectBusiness.vue';
import FinanceService from './components/FinanceService.vue';
export default {
  components: {
    FindProject,
    FindScheme,
    Bidding,
    ProjectBusiness,
    FinanceService
  },
  data () {
    return {
      current: 0,
      tabs: [
        { id: 'FindProject', title: '找项目', remark: '平台海量行业项目信息供你选择' },
        { id: 'FindScheme', title: '找方案', remark: '平台海量行业项目信息供你选择' },
        { id: 'Bidding', title: '招投标', remark: '平台海量行业项目信息供你选择' },
        { id: 'ProjectBusiness', title: '项目经营', remark: '平台海量行业项目信息供你选择' },
        { id: 'FinanceService', title: '财务服务', remark: '平台海量行业项目信息供你选择' }
      ]
    }
  },
  methods: {
    currentChange(index) {
      this.current = index;
    }
  }
}
</script>

<style lang="less" scoped>
.tab-company {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.06);
  .tabs-nav {
    .nav-item {
      width: 242px;
      height: 90px;
      padding: 20px;
      background-image: url("~@/assets/img/home/bg_tab_company_nav.png");
      background-size: cover;
      background-position: center;
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &.is-active {
        background-image: url("~@/assets/img/home/bg_tab_company_nav_active.png");
        background-size: cover;
        background-position: center;
        .title,
        .remark {
          color: #ffffff;
        }
      }
      .title {
        color: #1E1004;
        font-size: 20px;
        // font-weight: bold;
      }
      .remark {
        margin-top: 12px;
        color: #9E9E9E;
        font-size: 14px;
      }
    }
  }
  .tabs-content {
    flex: 1;
    .tabpanel {
      height: 100%;
      display: none;
      overflow: auto;
      &.is-active {
        display: block;
      }
    }
  }
}
</style>