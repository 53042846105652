const localCache = {
	/**
	 * @description: 根据键获取本地缓存数据
	 * @param {String} key 键
	 */
	get(key) {
		if (!key) return;
		var value = window.localStorage.getItem(key);
		if (value !== null) {
			if (typeof(value)=='string') {
				return value;
			}else{
				try {
					value = JSON.parse(value);
				} catch (e) { console.log("错误处理") }
			}
			
		}
		return value;
	},
	/**
	 * @description: 根据键设置本地缓存数据
	 * @param {String} key 键
	 * @param {Any} data 数据
	 */
	set(key, data) {
		if (!key) return;
		if (typeof data !== 'string') {
			data = JSON.stringify(data);
		}
		window.localStorage.setItem(key, data);
	},
	/**
	 * @description: 根据键删除本地缓存数据
	 * @param {String} key 键
	 */
	remove(key) {
		if (!key) return true;
		//删除逻辑要进行获取判断验证
		window.localStorage.removeItem(key);
		return this.get(key) === null ? true : false;
	},
	/**
	 * @description: 清空本地缓存数据
	 */
	clear() {
		window.localStorage.clear();
	}
};

export default localCache
