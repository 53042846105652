<template>
 
  <div class="tab-service">
    <div class="tab-service-item" v-for="(item  , index) in dataList" :key="item.code" >
      <div class="tab-service-item__header" >
        <div class="title">{{ item.title }}</div>
        <img class="icon" :src="item.imageUrl">
      </div>
      <div class="tab-service-item__content">
        <div class="explain">{{ item.content }}</div>
        <div class="tag">
          <div class="tag-title">典型功能</div>
          <div class="tag-box">
            <div class="tag-item" v-for="tag in item.typeFunctionList" :key="tag">{{ tag }}</div>
          </div>
        </div>
        <div class="consult-btn" @mouseleave="hiddenLog()"  @mouseover="showDialog(index)">立即咨询</div>
      </div>
       <div  v-if="currentIndex == index" class="cover">
        <span class="title">微信扫一扫，沟通更便捷</span>
        <img src="@/assets/img/home/wx-x.png">
      
    </div>
    </div>
   

  </div>
</template>

<script>
import { getListHotService } from "@/api/home/home.js";
export default {
  data () {
    return {
      dataList: [],
      currentIndex:-1,
   
    }
  },
  created () {
    getListHotService().then((res) => {
      this.dataList = res.rows;
    });
  },
 methods:{
    showDialog(index){
      this.currentIndex = index
    },
    hiddenLog(){
    this.currentIndex = -1
    }
  }
}
</script>

<style lang="less" scoped>
.tab-service {
  display: flex;
  .tab-service-item {
    position: relative;
    width: calc(100% / 5 - 4px);
    margin-left: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
    &:first-child {
      margin-left: 0;
    }
    .tab-service-item__header {
      height: 56px;
      padding: 0 15px 0 24px;
      background-image: linear-gradient(90deg, #DFA51B, #EFCF4F);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }
      .icon {
        width: 40px;
        height: 40px;
      }
    }
    .tab-service-item__content {
      height: 265px;
      padding: 15px 20px ;
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-top: none;
      display: flex;
      flex-direction: column;
      .explain {
        min-height: 60px;
        color: #9E9E9E;
        font-size: 14px;
        text-align: justify;
      }
      .tag {
        flex: 1;
        .tag-title {
          margin-bottom: 14px;
          padding-left: 12px;
          color: #2c2c2c;
          font-size: 14px;
          line-height: 20px;
          position: relative;
          &::before {
            content: '';
            width: 3px;
            height: 16px;
            background-color: #DFA51B;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .tag-box {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          .tag-item {
            height: 31px;
            border: 1px solid #E8E8E8;
            line-height: 29px;
            color: #2C2C2C;
            font-size: 14px;
            text-align: center;
          }
        }
      }
      .consult-btn {
        height: 35px;
        border: 1px solid #EFCF4F;
        color: #DFA51B;
        font-size: 14px;
        line-height: 33px;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      .consult-btn:hover{
        background-color:  #DFA51B;
        color: #fff;
      }
    }
  }
  .cover{
    position: absolute;
    border: 1px solid #ececec;
    left: 10%;
    bottom:60px;
    width: 80%;
    height: 60%;
    display: flex;
     background-color: white;
    flex-direction: column;
    align-items: center;

       img{
              width: 150px;
            }
    .title{
      margin: 15px 0 10px 0;
      color: #666;
      font-size: 14px;
       
    }

  }
}
 
</style>